import { takeLatest, put, call, all, select, take } from 'redux-saga/effects';
import camelize from 'camelize';

import {
  INIT_APP,
  GET_ME_FAILED,
  GET_ME_REQUESTED,
  getMe as mkGetMe,
  fetchChapters as mkfetchChapters,
  fetchGrades as mkFetchGrades,
  initApp as mkInitApp,
  getLeaderBoardData as mkGetLeaderBoardData,
  userLoggedOut as mkUserLoggedOut,
  getAllAssessmentClasses as mkGetAllAssessmentClasses,
  setLoadingFilteredClasses as mkSetLoadingFilteredClasses,
  GET_PIN_SUBMIT_REQUESTED,
  GET_CREATE_CLASS_REQUESTED,
  GET_CREATE_MULTI_CLASS_FAILED,
  GET_SCHOOL_GRADES_REQUESTED,
  GET_LEVEL_DROPDOWN_ITEMS_REQUESTED,
  GET_SCHOOL_PIN_SUBMISSION_REQUESTED,
  GET_SCHOOL_CODE_SUBMISSION_REQUESTED,
  GET_USER_LOGIN_REQUESTED,
  GET_USER_PASSWORD_LINK_REQUESTED,
  POST_NEW_USER_REQUESTED,
  USER_LOGGED_OUT,
  USER_LOGGED_OUT_SUCCEEDED,
  GET_USER_REFRESH_REQUESTED,
  GET_USER_REFRESH_SUCCEEDED,
  GET_USER_REFRESH_FAILED,
  POST_NEW_USER_FAILED,
  POST_NEW_USER_SUCCEEDED,
  submitCreateClass as mkSubmitCreateClass,
  submitClassPin as mkSubmitCreatePin,
  GET_USER_LOGIN_FAILED,
  GET_USER_LOGIN_SUCCEEDED,
  GET_ME_SUCCEEDED,
  GET_SCHOOL_CODE_SUBMISSION_FAILED,
  GET_SCHOOL_CODE_SUBMISSION_SUCCEEDED,
  GET_SCHOOL_PIN_SUBMISSION_FAILED,
  GET_SCHOOL_PIN_SUBMISSION_SUCCEEDED,
  GET_SCHOOL_GRADES_FAILED,
  GET_SCHOOL_GRADES_SUCCEEDED,
  GET_CREATE_CLASS_SUCCEEDED,
  GET_CHAPTERS_SUCCEEDED,
  GET_CHAPTERS_REQUESTED,
  GET_CHAPTERS_FAILED,
  SELECT_CHAPTER_REQUESTED,
  GET_CONFIG_TYPE_REQUESTED,
  GET_CONFIG_TYPE_SUCCEEDED,
  GET_CONFIG_TYPE_FAILED,
  GET_STUDENT_REPORT_REQUESTED,
  GET_STUDENT_REPORT_SUCCEEDED,
  GET_STUDENT_REPORT_FAILED,
  GET_TESTS_REQUESTED,
  GET_TESTS_SUCCEEDED,
  GET_TESTS_FAILED,
  SELECT_TEST_REQUESTED,
  GET_EXAMS_QUESTIONS_REQUESTED,
  GET_EXAMS_QUESTIONS_SUCCEEDED,
  GET_EXAMS_QUESTIONS_FAILED,
  CREATE_LIST_FOR_LOADER,
  getTests as mkGetTest,
  getAssessmentDraftClasses as mkGetAssessmentDraftClasses,
  LAUNCH_EXAM_REQUESTED,
  LAUNCH_EXAM_SUCCEEDED,
  LAUNCH_EXAM_FAILED,
  FETCH_EXAM_REPORT_REQUESTED,
  FETCH_EXAM_REPORT_SUCCEDED,
  FETCH_EXAM_REPORT_FAILED,
  RESET_TEST_ATTEMPTS_REQUESTED,
  RESET_TEST_ATTEMPTS_SUCCEEDED,
  RESET_TEST_ATTEMPTS_FAILED,
  GET_STUDENTS_REQUESTED,
  GET_STUDENTS_SUCCEEDED,
  GET_STUDENTS_FAILED,
  DELETE_STUDENT_REQUESTED,
  DELETE_STUDENT_SUCCEEDED,
  DELETE_STUDENT_FAILED,
  FETCH_STUDENT_WISE_REPORT_REQUESTED,
  FETCH_STUDENT_WISE_REPORT_SUCCEDED,
  FETCH_STUDENT_WISE_REPORT_FAILED,
  CREATE_ANNOUNCEMENT_REQUESTED,
  CREATE_ANNOUNCEMENT_SUCCEEDED,
  CREATE_ANNOUNCEMENT_FAILED,
  GET_MEETING_URL_REQUESTED,
  GET_MEETING_URL_SUCCEEDED,
  GET_MEETING_URL_FAILED,
  GET_START_CONFERENCE_REQUESTED,
  GET_START_CONFERENCE_SUCCEEDED,
  GET_START_CONFERENCE_FAILED,
  FETCH_DATE_RANGE_REQUESTED,
  FETCH_DATE_RANGE_SUCCEDED,
  FETCH_DATE_RANGE_FAILED,
  SELECT_ATTENDENCE_MONTH,
  SELECT_ATTENDENCE_MONTH_SUCCEDED,
  SELECT_ATTENDENCE_MONTH_FAILED,
  GET_STUDENT_SESSION_REQUESTED,
  GET_STUDENT_SESSION_SUCCEEDED,
  GET_STUDENT_SESSION_FAILED,
  FETCH_PRACTICED_SUCCEDED,
  FETCH_PRACTICED_FAILED,
  FETCH_PRACTICED_REQUESTED,
  GET_STUDENT_STUCK_REQUESTED,
  GET_STUDENT_STUCK_SUCCEDED,
  GET_STUDENT_STUCK_FAILED,
  FETCH_QUESTION_HISTORY_REQUESTED,
  FETCH_QUESTION_HISTORY_SUCCEDED,
  FETCH_QUESTION_HISTORY_FAILED,
  GET_CHANGE_PASSWORD_REQUESTED,
  GET_CHANGE_PASSWORD_SUCCEEDED,
  GET_CHANGE_PASSWORD_FAILED,
  GET_USER_LOGOUT_REQUESTED,
  GET_USER_LOGOUT_SUCCEEDED,
  GET_USER_LOGOUT_FAILED,
  GET_SYLLABUS_REQUESTED,
  GET_SYLLABUS_SUCCEEDED,
  GET_SYLLABUS_FAILED,
  CONFIG_DATA_REQUESTED,
  CONFIG_DATA_SUCCEEDED,
  CONFIG_DATA_FAILED,
  fetchStudentReport as mkFetchStudentReport,
  selectClass,
  deleteNewClass,
  getConfigTypeInfo as mkGetConfigTypeInfo,
  setSyncLeaderBoardDataLoader as mkSetSyncLeaderBoardDataLoader,
  setEditAIFeedbackCriteriaLoader as mkSetEditAIFeedbackCriteriaLoader,
  setEditOpenEndedFeedbackLoader as mkSetEditOpenEndedFeedbackLoader,
  setEditAIGradingFeedbackLoader as mkSetEditAIGradingFeedbackLoader,
  GET_QUESTION_REQUESTED,
  GET_QUESTION_FAILED,
  GET_QUESTION_SUCCEEDED,
  PUBLISH_TEACHER_TOPIC_REQUESTED,
  PUBLISH_TEACHER_TOPIC_SUCCEDDED,
  PUBLISH_TEACHER_TOPIC_FAILED,
  GET_USER_PASSWORD_LINK_FAILED,
  GET_USER_PASSWORD_LINK_SUCCEEDED,
  VERIFY_RESET_CODE_REQUESTED,
  VERIFY_RESET_CODE_FAILED,
  VERIFY_RESET_CODE_SUCCEEDED,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCEEDED,
  RESET_PASSWORD_REQUESTED,
  POST_REPORT_REQUESTED,
  POST_REPORT_SUCCEEDED,
  POST_REPORT_FAILED,
  GET_QUESTION_CONTENT_REQUESTED,
  GET_QUESTION_CONTENT_SUCCEEDED,
  GET_QUESTION_CONTENT_FAILED,
  GET_BROKEN_DOWN_REQUESTED,
  GET_BROKEN_DOWN_SUCCEEDED,
  GET_BROKEN_DOWN_FAILED,
  DELETE_ANNOUNCEMENT_SUCCEEDED,
  DELETE_ANNOUNCEMENT_FAILED,
  DELETE_ANNOUNCEMENT_REQUESTED,
  REGISTER_CLIENT_USER_REQUESTED,
  REGISTER_CLIENT_USER_SUCCEEDED,
  REGISTER_CLIENT_USER_FAILED,
  CLIENT_MAIL_RESEND_REQUESTED,
  CLIENT_MAIL_RESEND_SUCCEEDED,
  CLIENT_MAIL_RESEND_FAILED,
  REGISTER_NEW_STUDENT_REQUESTED,
  REGISTER_NEW_STUDENT_FAILED,
  REGISTER_NEW_STUDENT_SUCCEEDED,
  resetNewStudentRegistration,
  loginUser as mkLoginUser,
  GET_CREATE_CLASS_FAILED,
  CHECK_STUDENT_EXISTS,
  CHECK_STUDENT_EXISTS_DATA,
  BULK_REGISTER_STUDENTS_REQUESTED,
  BULK_REGISTER_STUDENTS_SUCCEEDED,
  BULK_REGISTER_STUDENTS_FAILED,
  GET_TOPIC_LAUNCH_SETTINGS_SUCCEEDED,
  GET_TOPIC_LAUNCH_SETTINGS_FAILED,
  SET_TOPIC_LAUNCH_SETTINGS_SUCCEEDED,
  SET_TOPIC_LAUNCH_SETTINGS_FAILED,
  GET_TOPIC_LAUNCH_SETTINGS_REQUESTED,
  SET_TOPIC_LAUNCH_SETTINGS_REQUESTED,
  GET_ASSESSMENT_LAUNCH_SETTINGS_REQUESTED,
  SET_ASSESSMENT_LAUNCH_SETTINGS_REQUESTED,
  GET_ASSESSMENT_LAUNCH_SETTINGS_SUCCEEDED,
  GET_ASSESSMENT_LAUNCH_SETTINGS_FAILED,
  SET_ASSESSMENT_LAUNCH_SETTINGS_SUCCEEDED,
  SET_ASSESSMENT_LAUNCH_SETTINGS_FAILED,
  ADD_QUESTION_TO_BOOKMARK_DATA_SUCCEEDED,
  ADD_QUESTION_TO_BOOKMARK_DATA_FAILED,
  REMOVE_QUESTION_FROM_BOOKMARK_DATA_SUCCEEDED,
  REMOVE_QUESTION_FROM_BOOKMARK_DATA_FAILED,
  REMOVE_QUESTION_FROM_BOOKMARK_DATA,
  ADD_QUESTION_TO_BOOKMARK_DATA,
  GET_CLASS_BOOKMARKS_DATA_SUCCEEDED,
  GET_CLASS_BOOKMARKS_DATA_FAILED,
  GET_CLASS_BOOKMARKS_DATA,
  SUBMIT_ASSESSMENT_REQUEST_SUCCEEDED,
  SUBMIT_ASSESSMENT_REQUEST_FAILED,
  SUBMIT_ASSESSMENT_REQUEST,
  UPDATE_BOOKMARK_DATA_SUCCEEDED,
  UPDATE_BOOKMARK_DATA_FAILED,
  UPDATE_BOOKMARK_DATA,
  FETCH_ASSESSMENT_REQUESTS_SUCCEEDED,
  FETCH_ASSESSMENT_REQUESTS_FAILED,
  FETCH_ASSESSMENT_REQUESTS,
  GET_ASSESSMENT_DRAFT_DATA_SUCCEEDED,
  GET_ASSESSMENT_DRAFT_DATA_FAILED,
  GET_ASSESSMENT_DRAFT_DATA,
  UPDATE_ASSESSMENT_DRAFT_DATA,
  UPDATE_ASSESSMENT_DRAFT_DATA_SUCCEEDED,
  UPDATE_ASSESSMENT_DRAFT_DATA_FAILED,
  PUBLISH_ASSESSMENT_REQUEST,
  PUBLISH_ASSESSMENT_REQUEST_SUCCEEDED,
  PUBLISH_ASSESSMENT_REQUEST_FAILED,
  SET_ACTIVE_VIEW_DRAFT,
  GET_LEADERBOARD_SUCCEEDED,
  GET_LEADERBOARD_FAILED,
  GET_LEADERBOARD_REQUESTED,
  GET_MYLEADERBOARD_REQUESTED,
  GET_MYLEADERBOARD_SUCCEEDED,
  GET_MYLEADERBOARD_FAILED,
  GET_TOGGLE_LEADERBOARD_SUCCEEDED,
  GET_TOGGLE_LEADERBOARD_FAILED,
  GET_TOGGLE_LEADERBOARD_REQUESTED,
  GET_LEADERBOARD_STUDENT_SUCCEEDED,
  GET_LEADERBOARD_STUDENT_FAILED,
  GET_LEADERBOARD_STUDENT_REQUESTED,
  ADD_STUDENT_IN_CLASS_REQUESTED,
  STUDENT_REGISTERED_DEVICES,
  STUDENT_REGISTERED_DEVICES_SUCCESS,
  STUDENT_REGISTERED_DEVICES_FAILED,
  FETCH_DATE_FILTERS_REQUESTED,
  FETCH_DATE_FILTERS_SUCCEEDED,
  FETCH_DATE_FILTERS_FAILED,
  GET_ALERT_REQUESTED,
  GET_ALERT_SUCCEEDED,
  GET_ALERT_FAILED,
  GET_NOTIFICATION_SESSION_REQUESTED,
  GET_NOTIFICATION_SESSION_SUCCEEDED,
  GET_NOTIFICATION_SESSION_FAILED,
  getNotificationAlert as mkGetNotificationAlert,
  GET_ATTEMTPS_NEW_REQUESTED,
  GET_ATTEMTPS_NEW_SUCCEDED,
  GET_ATTEMTPS_NEW_FAILED,
  GET_QUESTION_ATTEMPTS_REQUESTED,
  GET_QUESTION_ATTEMPTS_SUCCEEDED,
  GET_QUESTION_ATTEMPTS_FAILED,
  GET_LEVEL_DROPDOWN_ITEMS_FAILED,
  GET_LEVEL_DROPDOWN_ITEMS_SUCCEEDED,
  GET_LEVEL_SUBMISSION_FAILED,
  GET_LEVEL_SUBMISSION_SUCCEEDED,
  GET_LEVEL_SUBMISSION_REQUESTED,
  GET_CONNECT_GOOGLECLASS_REQUESTED,
  GET_CONNECT_GOOGLECLASS_SUCCEEDED,
  GET_CONNECT_GOOGLECLASS_FAILED,
  GET_GOOGLECLASSES_REQUESTED,
  GET_GOOGLECLASSES_SUCCEEDED,
  GET_GOOGLECLASSES_FAILED,
  GET_LINK_GOOGLECLASS_REQUESTED,
  GET_LINK_GOOGLECLASS_SUCCEEDED,
  GET_LINK_GOOGLECLASS_FAILED,
  GET_UNLINK_GOOGLECLASS_REQUESTED,
  GET_UNLINK_GOOGLECLASS_SUCCEEDED,
  GET_UNLINK_GOOGLECLASS_FAILED,
  GET_GOOGLECLASSESONBOARDING_SUCCEEDED,
  GET_GOOGLECLASSESONBOARDING_FAILED,
  GET_GOOGLECLASSESONBOARDING_REQUESTED,
  GET_GOOGLE_CLASSROOM_STUDENTS_REQUESTED,
  GET_GOOGLE_CLASSROOM_STUDENTS_SUCCEEDED,
  GET_GOOGLE_CLASSROOM_STUDENTS_FAILED,
  SYNC_GOOGLE_CLASSROOM_STUDENTS_REQUESTED,
  SYNC_GOOGLE_CLASSROOM_STUDENTS_SUCCEEDED,
  SYNC_GOOGLE_CLASSROOM_STUDENTS_FAILED,
  GET_SWITCH_GOOGLEACCOUNT_REQUESTED,
  GET_SWITCH_GOOGLEACCOUNT_FAILED,
  GET_TEACHER_GOOGLELOGIN_FAILED,
  GET_TEACHER_GOOGLELOGIN_REQUESTED,
  GET_TEACHER_GOOGLESIGNUP_REQUESTED,
  GET_TEACHER_GOOGLESIGNUP_FAILED,
  GET_MULTI_STUDENT_LEVEL_SUBMISSION_SUCCEEDED,
  GET_MULTI_STUDENT_LEVEL_SUBMISSION_FAILED,
  GET_MULTI_STUDENT_LEVEL_SUBMISSION_REQUESTED,
  GET_MULTI_STUDENT_GROUP_SUBMISSION_FAILED,
  GET_MULTI_STUDENT_GROUP_SUBMISSION_SUCCEEDED,
  GET_MULTI_STUDENT_GROUP_SUBMISSION_REQUESTED,
  GET_LEADERBOARD_DATA_SUCCEEDED,
  GET_LEADERBOARD_DATA_FAILED,
  GET_LEADERBOARD_DATA_REQUESTED,
  getLeaderBoardStudentData,
  updateSingleStudentNinjaTeam,
  GET_ALL_ATTEMPTS_REQUESTED,
  GET_ALL_ATTEMPTS_SUCCEEDED,
  GET_ALL_ATTEMPTS_FAILED,
  GET_ALL_ASSESSMENT_CLASSES_REQUESTED,
  GET_ALL_ASSESSMENT_CLASSES_FAILED,
  GET_ALL_ASSESSMENT_CLASSES_SUCCEEDED,
  GET_ASSESSMENT_DRAFT_CLASSES_SUCCEEDED,
  GET_ASSESSMENT_DRAFT_CLASSES_FAILED,
  GET_ASSESSMENT_DRAFT_CLASSES_REQUESTED,
  ADD_ASSESSMENT_DRAFT_CLASSES_REQUESTED,
  ADD_ASSESSMENT_DRAFT_CLASSES_SUCCEEDED,
  ADD_ASSESSMENT_DRAFT_CLASSES_FAILED,
  GET_ASSESSMENT_ADDED_CLASSES_REQUESTED,
  GET_ASSESSMENT_ADDED_CLASSES_SUCCEEDED,
  GET_ASSESSMENT_ADDED_CLASSES_FAILED,
  GET_FILTERED_ASSESSMENT_CLASSES_REQUESTED,
  GET_FILTERED_ASSESSMENT_CLASSES_SUCCEEDED,
  GET_FILTERED_ASSESSMENT_CLASSES_FAILED,
  RESET_STUDENT_PASSWORD_REQUESTED,
  RESET_STUDENT_PASSWORD_SUCCEEDED,
  RESET_STUDENT_PASSWORD_FAILED,
  SET_NEW_STUDENT_PASSWORD_SUCCEEDED,
  SET_NEW_STUDENT_PASSWORD_FAILED,
  SET_NEW_STUDENT_PASSWORD_REQUESTED,
  GET_CREATE_MULTI_CLASS_REQUESTED,
  GET_CREATE_MULTI_CLASS_SUCCEEDED,
  GET_STUDENT_EXCEL_DOWNLOAD_REQUESTED,
  GET_STUDENT_EXCEL_DOWNLOAD_SUCCEEDED,
  GET_STUDENT_EXCEL_DOWNLOAD_FAILED,
  GET_STUDENT_EXCEL_UPLOAD_REQUESTED,
  GET_STUDENT_EXCEL_UPLOAD_SUCCEEDED,
  GET_STUDENT_EXCEL_UPLOAD_FAILED,
  GET_CLASS_TEACHERS_REQUESTED,
  GET_CLASS_TEACHERS_FAILED,
  GET_CLASS_TEACHERS_SUCCEEDED,
  CHECK_TEACHER_EXISTS,
  CHECK_TEACHER_EXISTS_DATA,
  BULK_REGISTER_TEACHERS_REQUESTED,
  BULK_REGISTER_TEACHERS_SUCCEEDED,
  BULK_REGISTER_TEACHERS_FAILED,
  REMOVE_CLASS_TEACHERS_REQUESTED,
  REMOVE_CLASS_TEACHERS_SUCCEEDED,
  REMOVE_CLASS_TEACHERS_FAILED,
  GET_AI_FEEDBACK_REPORT_REQUESTED,
  GET_AI_FEEDBACK_REPORT_SUCCEEDED,
  GET_AI_FEEDBACK_REPORT_FAILED,
  EDIT_AI_FEEDBACK_CRITERIA_SUCCEEDED,
  EDIT_AI_FEEDBACK_CRITERIA_FAILED,
  EDIT_AI_FEEDBACK_CRITERIA_REQUESTED,
  GET_SCREEN_ERRORS_MAPPING_REQUESTED,
  GET_SCREEN_ERRORS_MAPPING_FAILED,
  GET_SCREEN_ERRORS_MAPPING_SUCCEEDED,
  SUBMIT_LOGIN_ERROR_REPORT_REQUESTED,
  SUBMIT_LOGIN_ERROR_REPORT_SUCCEEDED,
  SUBMIT_LOGIN_ERROR_REPORT_FAILED,
  SUBMIT_ERROR_REPORT_SUCCEEDED,
  SUBMIT_ERROR_REPORT_FAILED,
  SUBMIT_ERROR_REPORT_REQUESTED,
  MODIFY_STUDENT_DETAILS_REQUESTED,
  MODIFY_STUDENT_DETAILS_SUCCEEDED,
  MODIFY_STUDENT_DETAILS_FAILED,
  GET_SEARCH_TEACHERS_SUCCEEDED,
  GET_SEARCH_TEACHERS_FAILED,
  GET_SEARCH_TEACHERS_REQUESTED,
  EDIT_OPEN_ENDED_FEEDBACK_SUCCEEDED,
  EDIT_OPEN_ENDED_FEEDBACK_FAILED,
  EDIT_OPEN_ENDED_FEEDBACK_REQUESTED,
  MOCK_TEST_RESET_REQUESTED,
  MOCK_TEST_RESET_SUCCESS,
  MOCK_TEST_RESET_FAILURE,
  DOWNLOAD_TOPIC_REPORT_SUCCESS,
  DOWNLOAD_TOPIC_REPORT_FAILURE,
  DOWNLOAD_TOPIC_REPORT_REQUESTED,
  DOWNLOAD_MOCK_EXAM_REPORT_REQUESTED,
  DOWNLOAD_MOCK_EXAM_REPORT_SUCCESS,
  DOWNLOAD_MOCK_EXAM_REPORT_FAILURE,
  GET_AI_GRADING_FEEDBACK_REPORT_SUCCEEDED,
  GET_AI_GRADING_FEEDBACK_REPORT_FAILED,
  EDIT_AI_GRADING_FEEDBACK_SUCCEEDED,
  EDIT_AI_GRADING_FEEDBACK_FAILED,
  GET_AI_GRADING_FEEDBACK_REPORT_REQUESTED,
  EDIT_AI_GRADING_FEEDBACK_REQUESTED,
  fetchStudents as mkFetchStudents,
  FETCH_AUTO_GRADING_CTS_REQUESTED,
  FETCH_AUTO_GRADING_CTS_SUCCEEDED,
  FETCH_AUTO_GRADING_CTS_FAILED,
  GET_AUTO_GRADING_QUESTIONS_FAILED,
  GET_AUTO_GRADING_QUESTIONS_SUCCEEDED,
  GET_AUTO_GRADING_QUESTIONS_REQUESTED,
  fetchAutoGradingQuestions as mkFetchAutoGradingQuestions,
  UPLOAD_AUTO_GRADING_IMAGE_REQUESTED,
  UPLOAD_AUTO_GRADING_IMAGE_SUCCEEDED,
  UPLOAD_AUTO_GRADING_IMAGE_FAILED,
  FETCH_UNATTEMPTED_AUTO_GRADING_SUCCEEDED,
  FETCH_UNATTEMPTED_AUTO_GRADING_FAILED,
  FETCH_UNATTEMPTED_AUTO_GRADING_REQUESTED,
  EVALUATE_AUTO_GRADING_IMAGE_REQUESTED,
  EVALUATE_AUTO_GRADING_IMAGE_SUCCEEDED,
  EVALUATE_AUTO_GRADING_IMAGE_FAILED,
} from "./action";

import{ GET_CANVAS_CLASS_LIST_REQUEST,
  GET_CANVAS_CLASS_LIST_SUCCESS,
  GET_CANVAS_CLASS_LIST_FAILURE,
  GET_CANVAS_CHAPTER_LIST_REQUEST,
  GET_CANVAS_CHAPTER_LIST_SUCCESS,
  GET_CANVAS_CHAPTER_LIST_FAILURE} from './routes/CanvasTool/action'

import API from './api';
import {
  getNewClass,
  getSelectedClass,
  getSelectedTopic,
  getTopicLevelList,
  getChapterView,
  getSchoolGrades,
  getStateSelectedGrade,
  makeGetLeaderboardVal,
  getClientUserName,
  makeGetStudentRegistrationData,
  getUserSession,
  getPreferences,
  makeGetSelectedTopicLanguage,
  getLeaderBoardStudentDataSelector,
  getSelectedTestId
} from './selector';

import { useHistory } from 'react-router-dom';
import { parseQuestionResponse, b64DecodeUnicode, currentDomain } from './utils';
import { Mixpanel } from './Mixpanel';
import {
  FEAT_DISABLE_GRADES,
  FEAT_LEADERBOARD,
  customFeaturesList,
} from './customFeats'

export const JWT_TOKEN = 'cerebry-jwt-token';
const JWT_REFRESH = 'cerebry-jwt-refresh';

const RESET_PAGE = '/reset';
const CONTENT_PAGE = '/content';

export const getSavedJWT = (jwtKey) => {
  return Cookies.get(jwtKey);
};

const getPathName = (key) => {
  return location.pathname.indexOf(key) > -1 ? true : false;
};

export const saveJWT = (jwtKey, jwtValue) => {
  if (location.hostname === 'localhost') {
    Cookies.set(jwtKey, jwtValue);
  } else {
    Cookies.set(jwtKey, jwtValue, { expires: 30, domain: currentDomain });
  }
};

function toLocaleISOString(date) {
  function pad(n) {
    return ('0' + n).substr(-2);
  }

  var day = [
      date.getFullYear(),
      pad(date.getMonth() + 1),
      pad(date.getDate()),
    ].join('-'),
    time = [date.getHours(), date.getMinutes(), date.getSeconds()]
      .map(pad)
      .join(':');
  if (date.getMilliseconds()) time += '.' + date.getMilliseconds();
  var o = date.getTimezoneOffset(),
    h = Math.floor(Math.abs(o) / 60),
    m = Math.abs(o) % 60,
    o = o == 0 ? 'Z' : (o < 0 ? '+' : '-') + pad(h) + ':' + pad(m);
  return day + 'T' + time + o;
}

function* getMe() {
  try {
    const resp = yield call(API.getMe);
    if (resp.status >= 400) {
      yield put({
        type: GET_ME_FAILED,
        resp: camelize(resp),
      });

      if (resp.status != 401) {
        yield put(mkUserLoggedOut());
      }
    } else {
      // if(!(window.is_integrated == "true" || is_integrated=="true")) {
        saveJWT(JWT_TOKEN, resp.data.jwt);
        saveJWT(JWT_REFRESH, resp.data.jwt_refresh);
      // }
      API.check401('jwt ' + resp.data.jwt_refresh);
      yield put({
        type: GET_ME_SUCCEEDED,
        resp: camelize(resp),
      });
      const userSess = yield select(getUserSession);

      if(location.hostname != 'localhost' && userSess && userSess.user && userSess.user.isPisa) {
        window.location.href = 'https://teacher.cerebry.co/';
      }
      if(userSess && userSess.user && userSess.user.groups.some(i=>i==='school_admin'))
      yield put(mkGetAllAssessmentClasses(0,50));
    }
  } catch (e) {
    yield put({
      type: GET_ME_FAILED,
    });
    yield put(mkUserLoggedOut());
  }
}

export function removeJWT(jwtKey) {
  Cookies.remove(jwtKey);
}

function* initApp(dispatch) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const auth_refresh = urlParams.get("auth_refresh");
    const auth_tok = urlParams.get("auth_tok") || urlParams.get(JWT_TOKEN);
    const is_integrated = urlParams.get("is_integrated")
    console.log("initapp ===>", auth_tok);
    if(auth_tok) {
      saveJWT(JWT_TOKEN, auth_tok);
      saveJWT(JWT_REFRESH, '');
    }
    API.check401('jwt ' + getSavedJWT(JWT_REFRESH));
    const isResetPassword = getPathName(RESET_PAGE);
    const isContentPage = getPathName(CONTENT_PAGE);
    const jwtToken = getSavedJWT(JWT_TOKEN) || urlParams.get(JWT_TOKEN)
    const jwtTokenRefresh = getSavedJWT(JWT_REFRESH);
    if (!isResetPassword && !isContentPage) {
      if (!!jwtToken && jwtToken.length > 0) {
        console.log("initapp 2====>", jwtToken);
        API.setAccessToken('jwt ' + jwtToken);
        yield put(mkGetMe());
      } else {
        yield put(mkUserLoggedOut());
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}

function* getUserRefresh() {
  try {
    let userSess = yield select(getUserSession)
    const refreshToken = getSavedJWT(JWT_REFRESH);
    if (refreshToken && refreshToken.length > 0) {
      API.setAccessToken('jwt ' + refreshToken);
      const resp = yield call(API.refreshUser);
      if (resp.status < 400) {
        yield put({
          type: GET_USER_REFRESH_SUCCEEDED,
          resp: camelize(resp),
        });
        saveJWT(JWT_TOKEN, resp.data.jwt);
        API.setAccessToken('jwt ' + resp.data.jwt);
        yield put(mkInitApp());
      } else {
        yield put({
          type: GET_USER_REFRESH_FAILED,
        });

        yield put(mkUserLoggedOut());
      }
    } else {
      yield put({
        type: GET_USER_REFRESH_FAILED,
      });

      yield put(mkUserLoggedOut());
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_USER_REFRESH_FAILED,
      message: e.message,
    });
  }
}

function* registerUser(action) {
  const { newUser,language } = action;
  try {
    API.removeAccessToken();
    const resp = yield call(API.registerUser, newUser,language);
    if (resp.status >= 400) {
      yield put({
        type: POST_NEW_USER_FAILED,
        data: camelize(resp.data),
      });
    } else {
      saveJWT(JWT_TOKEN, resp.data.jwt);
      saveJWT(JWT_REFRESH, resp.data.jwt_refresh);
      Mixpanel.identify(resp.data.id);
      Mixpanel.people.set({
        "$email": resp.data.email,
      });
      Mixpanel.people.set_once({
        "FirstSigninDate": new Date()
      });
      yield put({
        type: POST_NEW_USER_SUCCEEDED,
        data: camelize(resp.data),
      });

      yield put(mkInitApp());
    }
  } catch (e) {
    yield put({
      type: POST_NEW_USER_FAILED,
      message: e.message,
    });
  }
}

function* loginUser(action) {
  const { user,language } = action;
  try {
    API.removeAccessToken();
    const resp = yield call(API.loginUser, user,language);
    if (resp.status >= 400) {
      yield put({
        type: GET_USER_LOGIN_FAILED,
        data: camelize(resp.data),
      });
    } else {
      saveJWT(JWT_TOKEN, resp.data.jwt);
      saveJWT(JWT_REFRESH, resp.data.jwt_refresh);
      Mixpanel.identify(resp.data.id);
      Mixpanel.people.set({
        "$email": resp.data.email,
        "LastSigninDate": new Date(),
        "SchoolID": resp.data.teacher_school,
      });
      Mixpanel.people.set_once({
        "FirstSigninDate": new Date()
      });
      Mixpanel.track("TeacherProduct_SigninClicked", {
        "DemoAccount?": resp.data.is_demo_user ? "Yes" : "No",
      });

      yield put({
        type: GET_USER_LOGIN_SUCCEEDED,
        data: camelize(resp.data),
      });

      yield put(mkInitApp());
    }
  } catch (e) {
    yield put({
      type: GET_USER_LOGIN_FAILED,
      message: e.message,
    });
  }
}

function* forgotPassword(action) {
  const { user,language } = action;
  try {
    const resp = yield call(API.forgotPassword, user,language);
    if (resp.status >= 400) {
      yield put({
        type: GET_USER_PASSWORD_LINK_FAILED,
        data: camelize(resp.data),
      });
    } else {
      yield put({
        type: GET_USER_PASSWORD_LINK_SUCCEEDED,
      });
    }
  } catch (e) {
    yield put({
      type: GET_USER_PASSWORD_LINK_FAILED,
      message: e.message,
    });
  }
}

function* verifyResetCode(action) {
  const { queryParams,language } = action;
  try {
    const resp = yield call(API.verifyResetCode, queryParams,language);
    if (resp.status >= 400) {
      yield put({
        type: VERIFY_RESET_CODE_FAILED,
        data: camelize(resp.data),
      });
    } else {
      yield put({
        type: VERIFY_RESET_CODE_SUCCEEDED,
        data: resp.data
      });
    }
  } catch (e) {
    yield put({
      type: VERIFY_RESET_CODE_FAILED,
      message: e.message,
    });
  }
}

function* resetPassword(action) {
  const { userInfo,language } = action;
  try {
    const resp = yield call(API.resetPassword, userInfo,language);
    if (resp.status >= 400) {
      yield put({
        type: RESET_PASSWORD_FAILED,
        data: camelize(resp.data),
      });
    } else {
      yield put({
        type: RESET_PASSWORD_SUCCEEDED,
        data: 'success'
      });
      const userObj = {};
      userObj.username = userInfo.username;
      userObj.account_type = 'teacher';
      userObj.password = userInfo.password;
      yield put(mkLoginUser(userObj))
    }
  } catch (e) {
    yield put({
      type: RESET_PASSWORD_FAILED,
      message: e.message,
    });
  }
}

function* setNewStudentPassword(action) {
  try {
    const resp = yield call(API.setNewStudentPassword, action.username, action.password);
    if (resp.status < 400) {
      yield put({
        type: SET_NEW_STUDENT_PASSWORD_SUCCEEDED,
        data: camelize(resp.data),
      });
    } else {
      yield put({
        type: SET_NEW_STUDENT_PASSWORD_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: SET_NEW_STUDENT_PASSWORD_FAILED,
      message: e.message,
    });
  }
}

function* modifyStudentDetails(action) {
  try {
    const resp = yield call(API.modifyStudentDetails, action.payload);
    if (resp.status < 400) {
      yield put({
        type: MODIFY_STUDENT_DETAILS_SUCCEEDED,
        data: camelize(resp.data),
      });
    } else {
      yield put({
        type: MODIFY_STUDENT_DETAILS_FAILED,
        message: resp.data,
      });
    }
  } catch (e) {
    yield put({
      type: MODIFY_STUDENT_DETAILS_FAILED,
      message: e.message,
    });
  }
}


function* resetStudentPassword(action) {
  try {
    const resp = yield call(API.resetStudentPassword, action.username);
    if (resp.status < 400) {
      yield put({
        type: RESET_STUDENT_PASSWORD_SUCCEEDED,
        data: camelize(resp.data),
      });
    } else {
      yield put({
        type: RESET_STUDENT_PASSWORD_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: RESET_STUDENT_PASSWORD_FAILED,
      message: e.message,
    });
  }
}

export function* submitCode(action) {
  const { schoolObj } = action;
  try {
    const resp = yield call(API.submitCode, schoolObj);
    if (resp.status >= 400) {
      yield put({
        type: GET_SCHOOL_CODE_SUBMISSION_FAILED,
        resp,
      });
    } else {
      if (resp.data.show_pin_screen == false) {
        yield put(mkInitApp());
      } else {
        yield put({
          type: GET_SCHOOL_CODE_SUBMISSION_SUCCEEDED,
          data: camelize(resp.data),
          schoolCode: schoolObj.school_code,
        });
      }
    }
  } catch (e) {
    yield put({
      type: GET_SCHOOL_CODE_SUBMISSION_FAILED,
      message: e.message,
    });
  }
}

export function* submitPin(action) {
  const { schoolObj } = action;
  try {
    const resp = yield call(API.submitPin, schoolObj);
    if (resp.status >= 400) {
      yield put({
        type: GET_SCHOOL_PIN_SUBMISSION_FAILED,
        resp,
      });
    } else {
      yield put({
        type: GET_SCHOOL_PIN_SUBMISSION_SUCCEEDED,
      });
      const userSession = yield select(getUserSession);
      Mixpanel.track("TeacherProduct_SchoolSet", {
        "SchoolCode": schoolObj.school_code,
        "SchoolPin": schoolObj.school_pin
      });
      Mixpanel.people.set({
        "TeacherID": userSession.user.id,
        "$email": userSession.user.email
      });
      yield put(mkInitApp());
    }
  } catch (e) {
    yield put({
      type: GET_SCHOOL_PIN_SUBMISSION_FAILED,
      message: e.message,
    });
  }
}

export function* submitLevel(action) {
  const {levelObj} = action;
  try {
    const resp = yield call(API.submitLevel,levelObj);
    if (resp.status >= 400) {
      yield put({
        type: GET_LEVEL_SUBMISSION_FAILED,
        resp,
      });
    } else {
      yield put({
        type: GET_LEVEL_SUBMISSION_SUCCEEDED,
      });
  }
  }
  catch(e) {
    yield put({
      type: GET_LEVEL_SUBMISSION_FAILED,
      message: e.message,
    });
  }
}

export function* submitMultiStudentLevel(action) {
  const {levelObj} = action;
  try {
    const resp = yield call(API.submitMultiStudentLevel,levelObj);
    if (resp.status >= 400) {
      yield put({
        type: GET_MULTI_STUDENT_LEVEL_SUBMISSION_FAILED,
        resp,
      });
    } else {
      window.location.reload();
      yield put({
        type: GET_MULTI_STUDENT_LEVEL_SUBMISSION_SUCCEEDED,
      });
  }
  }
  catch(e) {
    yield put({
      type: GET_MULTI_STUDENT_LEVEL_SUBMISSION_FAILED,
      message: e.message,
    });
  }
}

export function* addMultiStudentInClassReq(action) {
  const {classCode, data} = action;
  try {
    const resp = yield call(API.addMultiStudentInClassReq,classCode,data);
    if (resp.status >= 400) {
      yield put({
        type: GET_MULTI_STUDENT_GROUP_SUBMISSION_FAILED,
        resp,
      });
    } else {
      window.location.reload();
      yield put({
        type: GET_MULTI_STUDENT_GROUP_SUBMISSION_SUCCEEDED,
      });
  }
  }
  catch(e) {
    yield put({
      type: GET_MULTI_STUDENT_GROUP_SUBMISSION_FAILED,
      message: e.message,
    });
  }
}

export function* fetchLevelDropdownItems(action) {
  try{
    let resp = yield call(API.fetchLevelDropdownItems, action.classCode);
    if (resp.status < 400) {
      yield put({
        type: GET_LEVEL_DROPDOWN_ITEMS_SUCCEEDED,
        data: camelize(resp.data),
      });
    }
    else
    {
      yield put({
        type: GET_LEVEL_DROPDOWN_ITEMS_FAILED,
        resp,
      });
    }
  }
  catch (e) {
    yield put({
      type: GET_LEVEL_DROPDOWN_ITEMS_FAILED,
      message: e.message,
    });
  }
}

export function* fetchGrades(action) {
  const selectedClass = yield select(getNewClass);
  let classObj = {};
  
  try {
    const pref = yield select(getPreferences)
    const featList = Array.isArray(pref) ? customFeaturesList(pref) : []
    const gradesDisabled = featList.includes(FEAT_DISABLE_GRADES)

    if(gradesDisabled) {
      const pathClassCode = location.pathname.split("/")[1]
      const data = [
        {
          id: 1,
          name: "Default Grade",
          classes: [
            {
              id: 1,
              name: "Default Class",
              code: pathClassCode,
              pin: null,
              announcement: [[""]],
              grade: 1
            }
          ]
        }
      ]
      yield put({
        type: GET_SCHOOL_GRADES_SUCCEEDED,
        data,
      });
      const urlParams = new URLSearchParams(window.location.search);
      const is_integrated = urlParams.get("is_integrated");
      const urlClassCode = urlParams.get("class-selected")
      const integratedClassCode = getSavedJWT("class-selected") || urlClassCode
      if((window.is_integrated=="true" || is_integrated=="true") && integratedClassCode) {
        data.forEach((grade) => {
          for (let i = 0; i < grade.classes.length; i++) {
            if (integratedClassCode.toUpperCase() == grade.classes[i].code.toUpperCase()) {
              classObj.id = grade.classes[i].id;
              classObj.name = grade.classes[i].name;
              classObj.code = grade.classes[i].code;
            }
          }
        })
      }
      else {
        classObj.id = data[0].classes[0].id;
        classObj.name = data[0].classes[0].name;
        classObj.code = data[0].classes[0].code;
      }
      yield put(selectClass(classObj));
      yield put(deleteNewClass());
    }
    else {
      const resp = yield call(API.fetchGrades);
      if (resp.status < 400) {
        yield put({
          type: GET_SCHOOL_GRADES_SUCCEEDED,
          data: camelize(resp.data),
        });
        const urlParams = new URLSearchParams(window.location.search);
        const is_integrated = urlParams.get("is_integrated");
        const urlClassCode = urlParams.get("class-selected")
        const integratedClassCode = getSavedJWT("class-selected") || urlClassCode
        if((window.is_integrated=="true" || is_integrated=="true") && integratedClassCode) {
          resp.data.forEach((grade) => {
            for (let i = 0; i < grade.classes.length; i++) {
              if (integratedClassCode.toUpperCase() == grade.classes[i].code.toUpperCase()) {
                classObj.id = grade.classes[i].id;
                classObj.name = grade.classes[i].name;
                classObj.code = grade.classes[i].code;
              }
            }
          })
          yield put(selectClass(classObj));
          yield put(deleteNewClass());
        }
        else if(!!action.classCode) {
          resp.data.forEach((grade) => {
            for (let i = 0; i < grade.classes.length; i++) {
              if (action.classCode == grade.classes[i].code) {
                classObj.id = grade.classes[i].id;
                classObj.name = grade.classes[i].name;
                classObj.code = grade.classes[i].code;
              }
            }
          })
          yield put(selectClass(classObj));
          yield put(deleteNewClass());
        }
        else if (!!selectedClass.classObj) {
          resp.data.forEach((grade) => {
            if (grade.id == selectedClass.selectedGrade.id) {
              grade.classes.forEach((gradeClass) => {
                if (selectedClass.classObj.id == gradeClass.id) {
                  classObj.id = selectedClass.classObj.id;
                  classObj.name = selectedClass.classObj.name;
                  classObj.code = selectedClass.classObj.code;
                  classObj.pin = gradeClass.pin;
                }
              });
            }
          });
          yield put(selectClass(classObj));
          yield put(deleteNewClass());
        }
      } else {
        yield put({
          type: GET_SCHOOL_GRADES_FAILED,
          resp,
        });
      }
    }
  } catch (e) {
    yield put({
      type: GET_SCHOOL_GRADES_FAILED,
      message: e.message,
    });
  }
}

export function* uploadStudentExcel(action){
  try {
    const resp = yield call(API.uploadStudentExcel, action.classCode, action.file,action.sendToStudent);

    if (resp.status < 400) {
      yield put({
        type: GET_STUDENT_EXCEL_UPLOAD_SUCCEEDED,
        data: camelize(resp.data),
      });
    } else {
      yield put({
        type: GET_STUDENT_EXCEL_UPLOAD_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_STUDENT_EXCEL_UPLOAD_FAILED,
      message: e.message,
    });
  }

}

export function* downloadStudentExcel(action) {
  try {
    const resp = yield call(API.downloadStudentExcel, action.classCode);
    if (resp.status < 400) {
      yield put({
        type: GET_STUDENT_EXCEL_DOWNLOAD_SUCCEEDED,
        data: resp.data,
      });
    } else {
      yield put({
        type: GET_STUDENT_EXCEL_DOWNLOAD_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_STUDENT_EXCEL_DOWNLOAD_FAILED,
      message: e.message,
    });
  }

}

export function* submitCreateMultiClass(action) {
  const classArr = action.classesArr;
  try {
    const resp = yield call(API.createMultiClass, classArr);
    if (resp.status < 400) {
      yield put({
        type: GET_CREATE_MULTI_CLASS_SUCCEEDED,
        data: camelize(resp.data),
      });
      
    } else {
      yield put({
        type: GET_CREATE_MULTI_CLASS_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_CREATE_MULTI_CLASS_FAILED,
    });
  }
}

export function* submitCreateClass(action) {
  const classObj = yield select(getNewClass);
  const params = {};
  params.name = classObj.className;
  params.grade = classObj.selectedGrade.id;
  if(action.selectGClassroom)
 { 
   params.google_class_code = action.selectGClassroom.code;
   params.google_class_name = action.selectGClassroom.name;}
  try {
    const resp = yield call(API.createClass, params);
    if (resp.status < 400) {
      yield put({
        type: GET_CREATE_CLASS_SUCCEEDED,
        data: camelize(resp.data),
      });
      Mixpanel.track("TeacherProduct_ClassCreated", {
        "ClassID": resp.data.id,
        "ClassName": resp.data.name,
        "Grade": classObj.selectedGrade.name
      });
      Mixpanel.people.set({
        "TeacherID": "",
        "$email": "",
      });
      Mixpanel.people.increment({
        "#NumberOfClasses": 1
      });
    } else {
      yield put({
        type: GET_CREATE_CLASS_FAILED,
        resp: camelize(resp),
      });
    }
  } catch (e) {
    yield put({
      type: GET_CREATE_CLASS_FAILED,
    });
  }
}

export function* submitClassPin(action) {
  const pin = action.pin;
  const classCode = yield select(getNewClassCode);
  try {
    const resp = yield call(API.createPin, pin, classCode);
    if (resp.status < 400) {
      yield put({
        type: GET_PIN_SUBMIT_SUCCEEDED,
      });
      yield put(mkFetchGrades());
    } else {
      yield put({
        type: GET_PIN_SUBMIT_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_PIN_SUBMIT_FAILED,
    });
  }
}

export function* fetchChapters(action) {
  const classes = yield select(getSelectedClass);
  try {
    const resp = yield call(
      API.fetchChapters,
      (classes && classes.code) ||
        (action && action.action && action.action.confClass),
    );
    yield put({
      type: GET_CLASS_BOOKMARKS_DATA,
      payload: {
        "classCode": classes.code
      }
    });
    const pref = yield select(getPreferences);
    //console.log(pref,"pref")
    const featList = Array.isArray(pref) ?
      customFeaturesList(pref) :
      [];
    const showLeaderboard = featList.includes(FEAT_LEADERBOARD)
    if(showLeaderboard) {
      const leaderboardResp = yield call(API.getClassLeaderboard, (classes && classes.code) ||
        (action && action.action && action.action.confClass))
      if(leaderboardResp.status < 400 && resp.status < 400) {
        const classObj = yield select(getSelectedClass);
        yield put({
          type: GET_CHAPTERS_SUCCEEDED,
          data: camelize(resp.data),
          classId:
            (classObj && classObj.id) ||
            (action && action.action && action.action.confClass),
          leaderboardData: camelize(leaderboardResp.data)
        });
      }
    }
    else if(resp.status < 400) {
      const classObj = yield select(getSelectedClass);
      yield put({
        type: GET_CHAPTERS_SUCCEEDED,
        data: camelize(resp.data),
        classId:
          (classObj && classObj.id) ||
          (action && action.action && action.action.confClass),
      });
    }
    else {
      yield put({
        type: GET_CHAPTERS_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_CHAPTERS_FAILED,
      message: e.message,
    });
  }
}

export function* selectChapter(action) {
  const classes = yield select(getSelectedClass);
  try {
    if (!classes) {
      yield put(mkFetchGrades(action.confClass));
      yield put(mkfetchChapters(action));
    }
  } catch (e) {}
}

export function* fetchConfigType(action) {
  try {
    const resp = yield call(
      API.fetchConfigType,
      action.classCode,
      action.topicId,
    );
    let topicLevelList = resp.data.topic_levels;
    let topicLevelNameMap = resp.data.topic_level_display_name_mappings;
    let configTypes = [[], [], []];
    if (resp.status < 400) {
      resp.data.config_types.forEach((configType, index) => {
        const newConfigType = { ...configType };
        newConfigType.name = 'Q' + (index + 1);
        if (configType.topic_level_name == topicLevelList[0])
          configTypes[0].push(newConfigType);
        if (configType.topic_level_name == topicLevelList[1])
          configTypes[1].push(newConfigType);
        if (configType.topic_level_name == topicLevelList[2])
          configTypes[2].push(newConfigType);
      });
      yield put({
        type: GET_CONFIG_TYPE_SUCCEEDED,
        configTypes: camelize(configTypes),
        topicLevelList,
        topicLevelNameMap,
      });
      yield put(mkFetchStudentReport(action.classCode, action.topicId));
    } else {
      yield put({
        type: GET_CONFIG_TYPE_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_CONFIG_TYPE_FAILED,
      message: e.message,
    });
  }
}

export function* fetchStudentReport(action) {
  try {
    const topicLevelList = yield select(getTopicLevelList);
    const resp = yield call(
      API.fetchStudentReport,
      action.classCode,
      action.topicId,
    );
    let students = [[], [], []];
    let notStarted = []
    if (resp.status < 400) {
      resp.data.forEach((student) => {
        if(student.not_started == true) {
          notStarted.push(student)
        }
        else {
          if (student.topic_level == topicLevelList[0])
            students[0].push(student);
          if (student.topic_level == topicLevelList[1])
            students[1].push(student);
          if (student.topic_level == topicLevelList[2])
            students[2].push(student);
        }
      });
      yield put({
        type: GET_STUDENT_REPORT_SUCCEEDED,
        studentReport: camelize(students),
        notStarted: camelize(notStarted),
      });
    } else {
      yield put({
        type: GET_STUDENT_REPORT_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_STUDENT_REPORT_FAILED,
      message: e.message,
    });
  }
}

export function* fetchTests(action) {
  try {
    const classCode = action.classCode;
    const resp = yield call(API.fetchExamList, classCode);
    if (resp.status < 400) {
      const testResp = camelize(resp.data);
      let resultTestObj = {};
      let finalObj = {};
      let currentMonth = `${(new Date()).getUTCMonth()+1}_${(new Date()).getUTCFullYear()}`
      let isCurrentMonthInList = false;
      if (testResp && testResp.length > 0) {
        for (let i = 0; i < testResp.length; i++) {
          const [month, year] = testResp[i].date;
          const key = `${month}_${year}`;
          if(key==currentMonth){
            isCurrentMonthInList=true
          }
          resultTestObj[key] = resultTestObj[key] || [];
          resultTestObj[key].push(testResp[i]);
        }
      }
      if(!isCurrentMonthInList){
        const key = currentMonth;
        finalObj[key] = []
        // resultTestObj[key] = [];
        finalObj = {
          ...finalObj,
          ...resultTestObj
        }
      }
      else{
        finalObj = resultTestObj;
      }
      // console.log(finalObj)
      yield put({
        type: GET_TESTS_SUCCEEDED,
        data: finalObj,
        classCode,
      });
    } else {
      yield put({
        type: GET_TESTS_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_TESTS_FAILED,
      message: e.message,
    });
  }
}

const computeSession = (sessionProps) => {
  let newObj = sessionProps;
  newObj.forEach((value) => {
    if (!value.hasOwnProperty('invalid_appeal')) {
      let newStartTime = new Date(value.start_time).toLocaleTimeString().split(':');
      let newEndTime = new Date(value.end_time).toLocaleTimeString().split(':');
      if (newStartTime[2].toUpperCase().indexOf('PM') > -1 && newStartTime[0] !== '12')
        newStartTime[0] = (Number(newStartTime[0]) + 12).toString();
      if (newEndTime[2].toUpperCase().indexOf('PM') > -1 && newEndTime[0] !== '12')
        newEndTime[0] = (Number(newEndTime[0]) + 12).toString();
      let calnewStartTime = newStartTime[0] + ':' + newStartTime[1];
      let calnewEndTime = newEndTime[0] + ':' + newEndTime[1];
      let newStartDate = new Date(value.start_time).toLocaleDateString();
      let newEndDate = new Date(value.end_time).toLocaleDateString();
      value.start_time = calnewStartTime + ' ' + newStartDate;
      value.end_time = calnewEndTime + ' ' + newEndDate;
    }
  });
  return newObj;
};

export function* getStudentSession(action) {
  const { studentId, topicId } = action;
  try {
    const resp = yield call(API.getStudentSession, topicId, studentId);
    if (resp.status < 400) {
      const computedSession = computeSession(resp.data);
      yield put({
        type: GET_STUDENT_SESSION_SUCCEEDED,
        studentSession: computedSession,
        studentId,
      });
    } else {
      yield put({
        type: GET_STUDENT_SESSION_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_STUDENT_SESSION_FAILED,
      message: e.message,
    });
  }
}

export function* fetchExamQuestions(action) {
  const { userId, questions, questionIndex, attemptOrder } = action;
  const index = questionIndex;
  let i = questionIndex;
  try {
    yield put({
      type: CREATE_LIST_FOR_LOADER,
      index,
      userId,
      questions,
    });
    for (; i < questions.length; i++) {
      if (Array.isArray(questions[i])) {
        let questionResponse = [];
        for (let j = 0; j < questions[i].length; j++) {
          let resp = yield call(API.fetchExamQuestion, questions[i][j], userId, attemptOrder);
          while (resp.status >= 400 && resp.status !== 401) {
            resp = yield call(API.fetchExamQuestion, questions[i][j], userId, attemptOrder);
          }
          if (resp.status < 400) {
            questionResponse.push(resp);
          } else
            yield put({
              type: GET_EXAMS_QUESTIONS_FAILED,
              index: i,
              resp,
              userId,
            });

          if (j === questions[i].length - 1) {
            yield put({
              type: GET_EXAMS_QUESTIONS_SUCCEEDED,
              index: i,
              data: questionResponse,
              userId,
            });
          }
        }
      } else {
        let resp = yield call(API.fetchExamQuestion, questions[i], userId, attemptOrder);
        while (resp.status >= 400 && resp.status !== 401) {
          resp = yield call(API.fetchExamQuestion, questions[i], userId, attemptOrder);
        }
        if (resp.status < 400) {
          yield put({
            type: GET_EXAMS_QUESTIONS_SUCCEEDED,
            index: i,
            data: resp.data,
            userId,
          });
        } else
          yield put({
            type: GET_EXAMS_QUESTIONS_FAILED,
            index: i,
            resp,
          });
      }
    }
  } catch (e) {
    yield put({
      type: GET_EXAMS_QUESTIONS_FAILED,
      index: i,
      message: e.message,
    });
  }
}

export function* fetchAutoGradingQuestions(action) {
 const {configIds, userId} = action;

  try {
    yield put({
      type: CREATE_LIST_FOR_LOADER,
      questions: configIds,
    });
    for(let i=0; i<configIds.length; i++) {
      let resp = yield call(API.fetchExamQuestion, configIds[i].assessmentConfigTypeId, userId);
      while (resp.status >= 400 && resp.status !== 401) {
        resp = yield call(API.fetchExamQuestion, configIds[i].assessmentConfigTypeId, userId);
      }
      if (resp.status < 400) {
        yield put({
          type: GET_AUTO_GRADING_QUESTIONS_SUCCEEDED,
          data: resp.data,
          index: i,
          userId : userId,
          quesNo: configIds[i].questionNo,
        });
      } else {
        yield put({
          type: GET_AUTO_GRADING_QUESTIONS_FAILED,
          resp,
          index: i,
        });
      }
    }
  } catch (e) {
    yield put({
      type: GET_AUTO_GRADING_QUESTIONS_FAILED,
      message: e.message
    });
  }

}
export function* uploadAutoGradingImage(action){
  const { file,studentId, configId, questionId, quesNo, examId, subpartNo, classCode ,index} = action;
  // const classCode = yield select(makeSelectCurrentClass());
  
  try {
    let resp = yield call(API.uploadAutoGradingImage, file,studentId, classCode, configId, questionId, quesNo, examId, subpartNo);
    if(resp.status < 400) {
      yield put({
        type: UPLOAD_AUTO_GRADING_IMAGE_SUCCEEDED,
        resp,
        questionId,
        subpartInd : subpartNo ? subpartNo : 0,
        userId : studentId, 
        index : index,
      });
    }
    else yield put({
      type: UPLOAD_AUTO_GRADING_IMAGE_FAILED,
      resp
    });
  }
  catch(e) {
    yield put({
      type: UPLOAD_AUTO_GRADING_IMAGE_FAILED,
      resp: e.message
    });
  }
}
export function* fetchUnattemptedAutoGrading(action){
  const { examId, studentId } = action;
  try {
    let resp = yield call(API.fetchUnattemptedAutoGrading, examId, studentId);
    if(resp.status < 400) {
      yield put({
        type: FETCH_UNATTEMPTED_AUTO_GRADING_SUCCEEDED,
        data: resp.data
      });
    }
    else yield put({
      type: FETCH_UNATTEMPTED_AUTO_GRADING_FAILED,
      resp
    });
  }
  catch(e) {
    yield put({
      type: FETCH_UNATTEMPTED_AUTO_GRADING_FAILED,
      resp: e.message
    });
  }
}
export function* evaluateAutoGradingImage(action){
  const {examId, studentId} = action;
  try {
    let resp = yield call(API.evaluateAutoGradingImage, examId, studentId);
    if(resp.status < 400) {
      yield put({
        type: EVALUATE_AUTO_GRADING_IMAGE_SUCCEEDED,
        data: resp.data,
      });
    }
    else yield put({
      type: EVALUATE_AUTO_GRADING_IMAGE_FAILED,
      resp
    });
  }
  catch(e) {
    yield put({
      type: EVALUATE_AUTO_GRADING_IMAGE_FAILED,
      resp: e.message
    });
  }
}
export function* selectTest(action) {
  const classes = yield select(getSelectedClass);
  try {
    if (!!!classes) {
      yield put(mkGetTest(action.classCode));
    }
  } catch (e) {}
}

export function* launchExam(action) {
  try {
    const { testId, classCode } = action;
    const resp = yield call(API.launchTest, testId, classCode);
    if (resp.status < 400) {
      yield put({
        type: LAUNCH_EXAM_SUCCEEDED,
        data: camelize(resp.data),
        testId,
      });
    } else {
      yield put({
        type: LAUNCH_EXAM_FAILED,
        resp,
      });
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: LAUNCH_EXAM_FAILED,
      message: e.message,
    });
  }
}

export function* fetchExamReport(action) {
  const { examId, classCode } = action;
  try {
    const resp = yield call(API.fetchExamReport, classCode, examId);
    if (resp.status < 400) {
      let scoredArr = []
      let notStarted = []
      let yetToSubmit = []
      resp.data.forEach(stdObj => {
        if(stdObj.score == "Not Started") notStarted.push(stdObj)
        else if(stdObj.score == "Yet to submit") yetToSubmit.push(stdObj)
        else scoredArr.push(stdObj)
      })
      scoredArr = scoredArr.concat([...yetToSubmit])
      scoredArr = scoredArr.concat([...notStarted])
      yield put({
        type: FETCH_EXAM_REPORT_SUCCEDED,
        data: camelize(scoredArr),
        examId,
      });
    } else {
      yield put({
        type: FETCH_EXAM_REPORT_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_EXAM_REPORT_FAILED,
      message: e.message,
    });
  }
}

export function* resetTestAttempts(action) {
  const { classCode, examId, userId } = action;
  try {
    const resp = yield call(API.resetTestAttempts,classCode, examId, userId);
    if (resp.status < 400) {
      yield put({
        type: RESET_TEST_ATTEMPTS_SUCCEEDED,
        resp,
        userId,
      });
      // yield put({
      //   type:FETCH_EXAM_REPORT_REQUESTED,
      //   examId,
      //   classCode,
      // });

    } else {
      yield put({
        type: RESET_TEST_ATTEMPTS_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: RESET_TEST_ATTEMPTS_FAILED,
      message: e.message,
    });
  }
}

export function* fetchStudentWiseReport(action) {
  const { examId, userId, attemptOrder } = action;
  try {
    const resp = yield call(API.fetchStudentWiseReport, examId, userId, attemptOrder);
    if (resp.status < 400) {
      yield put({
        type: FETCH_STUDENT_WISE_REPORT_SUCCEDED,
        userId,
        data: camelize(resp.data),
      });
    } else {
      yield put({
        type: FETCH_STUDENT_WISE_REPORT_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      FETCH_STUDENT_WISE_REPORT_FAILED,
      message: e.message,
    });
  }
}

export function* fetchStudents() {
  const classes = yield select(getSelectedClass);
  try {
    const resp = yield call(API.fetchStudents, classes.code);
    if (resp.status < 400) {
      yield put({
        type: GET_STUDENTS_SUCCEEDED,
        data: camelize(resp.data),
        classCode: classes.code,
      });
      yield put(resetNewStudentRegistration());
    } else {
      yield put({
        type: GET_STUDENTS_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_STUDENTS_FAILED,
      message: e.message,
    });
  }
}

export function* removeStudent(action) {
  const { studentId } = action;
  try {
    const classes = yield select(getSelectedClass);
    const resp = yield call(API.removeStudent, classes.code, studentId);
    if (resp.status < 400) {
      yield put({
        type: DELETE_STUDENT_SUCCEEDED,
        resp: camelize(resp),
      });
      yield put(mkFetchStudents());
    } else {
      yield put({
        type: DELETE_STUDENT_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: DELETE_STUDENT_FAILED,
      message: e.message,
    });
  }
}

export function* createAnnouncement(action) {
  const { classCode, inputText, isUpdate } = action;
  try {
    const resp = yield call(API.createAnnouncement, classCode, inputText);
    if (resp.status < 400) {
      const selectedGrade = yield select(getStateSelectedGrade);
      const schoolGrades = yield select(getSchoolGrades);
      let classIndex = selectedGrade.classes.findIndex(x=>x.code===action.classCode)
      let schoolGradeIndex = schoolGrades.findIndex(x=>x.name===selectedGrade.name)
      yield put({
        type: CREATE_ANNOUNCEMENT_SUCCEEDED,
        text: resp.data.text,
        classCode,
        classIndex,
        schoolGradeIndex,
      });
      const userSession = yield select(getUserSession);
      Mixpanel.track(
				isUpdate
					? "TeacherProduct_AnnouncementUpdated"
					: "TeacherProduct_AnnouncementSet",
				{
					Message: inputText,
				}
			);
      Mixpanel.people.set({
        "$email": userSession.user.email,
        "TeacherID": userSession.user.id,
        "MostRecentAnnouncementDate": isUpdate ? undefined : new Date()
      });
      if(!isUpdate) {
        Mixpanel.people.increment({
          "#NumberOfAnnouncements": 1
        });
      }
    } else {
      yield put({
        type: CREATE_ANNOUNCEMENT_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: CREATE_ANNOUNCEMENT_FAILED,
      message: e.message,
    });
  }
}

export function* deleteAnnouncement(action) {
  const { classCode, orgAnnouncement } = action;
  try {
    const resp = yield call(API.deleteAnnouncement, classCode);
    if (resp.status < 400) {
      const selectedGrade = yield select(getStateSelectedGrade);
      const schoolGrades = yield select(getSchoolGrades);
      let classIndex = selectedGrade.classes.findIndex(x=>x.code===action.classCode)
      let schoolGradeIndex = schoolGrades.findIndex(x=>x.name===selectedGrade.name)
      yield put({
        type: DELETE_ANNOUNCEMENT_SUCCEEDED,
        text: '',
        classCode,
        classIndex,
        schoolGradeIndex,
      });
      const userSession = yield select(getUserSession);
      Mixpanel.track("TeacherProduct_AccouncementDeleted", {
        "DeletedMessage": orgAnnouncement
      });
      Mixpanel.people.set({
        "$email": userSession.user.email,
        "TeacherID": userSession.user.id,
      });
    } else {
      yield put({
        type: DELETE_ANNOUNCEMENT_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: DELETE_ANNOUNCEMENT_FAILED,
      message: e.message,
    });
  }
}

export function* startConference() {
  const selectedClass = yield select(getSelectedClass);
  const isoTime = toLocaleISOString(new Date());
  const params = {
    local_time: isoTime,
  };
  try {
    const resp = yield call(API.startConference, selectedClass.code, params);
    if (resp.status < 400) {
      yield put({
        type: GET_START_CONFERENCE_SUCCEEDED,
        confUrl: resp.data.url,
      });
    } else {
      yield put({
        type: GET_START_CONFERENCE_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_START_CONFERENCE_FAILED,
      message: e.message,
    });
  }
}

export function* getMeetingUrl(action) {
  const { code } = action;
  try {
    const resp = yield call(API.getMeetingUrl, code);
    if (resp.status <= 400) {
      const status = resp.status < 400 ? resp.data.url : undefined;
      const announcement =
        resp.data.announcements &&
        resp.data.announcements.length > 0 &&
        resp.data.announcements[0].length > 0
          ? resp.data.announcements[0][0]
          : undefined;
      yield put({
        type: GET_MEETING_URL_SUCCEEDED,
        confUrl: status,
        announcement,
      });
    } else {
      yield put({
        type: GET_MEETING_URL_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_MEETING_URL_FAILED,
      message: e.message,
    });
  }
}

export function* fetchDateRange(action) {
  const { classCode } = action;
  try {
    const resp = yield call(API.getAttendanceDates, classCode);
    if (resp.status <= 400) {
      yield put({
        type: FETCH_DATE_RANGE_SUCCEDED,
        data: resp.data,
        classCode,
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_DATE_RANGE_FAILED,
      message: e.message,
    });
  }
}

export function* fetchAttendenceMonth(action) {
  const { dateDetails, noofDays } = action;
  const startDateString = `${dateDetails[1]}-${dateDetails[0]}-01`;
  const endDateString = `${dateDetails[1]}-${dateDetails[0]}-${noofDays}`;
  try {
    const selectedClass = yield select(getSelectedClass);
    const resp = yield call(
      API.attendListOfMonth,
      startDateString,
      endDateString,
      selectedClass,
    );
    if (resp.status < 400) {
      yield put({
        type: SELECT_ATTENDENCE_MONTH_SUCCEDED,
        resp,
        dateDetails,
        classCode: selectedClass.code,
      });
    } else
      yield put({
        type: SELECT_ATTENDENCE_MONTH_FAILED,
        resp,
      });
  } catch (e) {
    yield put({
      type: SELECT_ATTENDENCE_MONTH_FAILED,
      message: e.message,
    });
  }
}

export function* fetchPracticedQuestion(action) {
  const { studentId, topicId } = action;
  try {
    const resp = yield call(API.getPracticedQuestion, topicId, studentId);
    if (resp.status < 400) {
      yield put({
        type: FETCH_PRACTICED_SUCCEDED,
        studentPracticed: resp.data,
      });
    } else {
      yield put({
        type: FETCH_PRACTICED_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_PRACTICED_FAILED,
      message: e.message,
    });
  }
}

export function* getStudentStuck(action) {
  const { studentId, topicId } = action;
  try {
    const resp = yield call(API.getStudentStuck, topicId, studentId);
    if (resp.status < 400) {
      yield put({
        type: GET_STUDENT_STUCK_SUCCEDED,
        studentStuck: resp.data,
      });
      for(let i=0; i<resp.data.length; i++) {
        const ques = resp.data[i]
        if(ques.hasOwnProperty("broken_down_concepts")) {
          const brokenList = Object.values(ques.broken_down_concepts)
          for(let j=0; j<brokenList.length; j++) {
            const brokenQues = brokenList[j]
            if(brokenQues.question) {
              const brokenData = {...brokenQues}
              brokenData.question = b64DecodeUnicode(brokenData.question);
              yield put({
                type: FETCH_QUESTION_HISTORY_SUCCEDED,
                data: camelize(brokenData),
                questionId: brokenData.id,
              });
            }
          }
        }
      }
      const quesData = resp.data[resp.data.length-1]
      if(!quesData.valid_response) {
        quesData.question = b64DecodeUnicode(quesData.question);
        yield put({
          type: FETCH_QUESTION_HISTORY_SUCCEDED,
          data: camelize(quesData),
          questionId: quesData.id,
        });
      }
    } else {
      yield put({
        type: GET_STUDENT_STUCK_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_STUDENT_SESSION_FAILED,
      message: e.message,
    });
  }
}

export function* fetchQuestionHistory(action) {
  const { questionId } = action;
  try {
    const resp = yield call(API.fetchQuestionHistory, questionId);
    if (resp.status < 400) {
      const question = {};
      if (resp.data.question) {
        question.question = b64DecodeUnicode(resp.data.question);
      }
      if (resp.data.answer_type) {
        question.answer_type = b64DecodeUnicode(resp.data.answer_type);
      }
      if (resp.data.solution) {
        question.solution = b64DecodeUnicode(resp.data.solution);
      }
      if (resp.data.id) {
        question.id = resp.data.id;
      }
      question.submitted_answer =
        resp.data.submitted_answer !== null
          ? typeof resp.data.submitted_answer === 'string'
            ? parseQuestionResponse(resp.data.submitted_answer)
            : resp.data.submitted_answer
          : null;
      question.show_interactive_table=resp.data.show_interactive_table
      yield put({
        type: FETCH_QUESTION_HISTORY_SUCCEDED,
        data: camelize(question),
        questionId,
      });
    } else {
      yield put({
        FETCH_QUESTION_HISTORY_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_QUESTION_HISTORY_FAILED,
      message: e.message,
    });
  }
}

export function* changePassword(action) {
  const { newPassword, oldPassword } = action;
  try {
    const resp = yield call(API.changePassword, oldPassword, newPassword);
    if (resp.status < 400) {
      yield put({
        type: GET_CHANGE_PASSWORD_SUCCEEDED,
      });
    } else {
      yield put({
        type: GET_CHANGE_PASSWORD_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_CHANGE_PASSWORD_FAILED,
      message: e.message,
    });
  }
}

export function* report(action) {
  const { data,configId } = action;
  try {
    const resp = yield call(API.report, data, configId);
    if (resp.status < 400) {
      yield put({
        type: POST_REPORT_SUCCEEDED,
        data
      });
    } else {
      yield put({
        type: POST_REPORT_FAILED,
        resp
      });
    }
  } catch (e) {
    yield put({
      type: POST_REPORT_FAILED,
      message: e.message
    });
    console.log('POST_REPORT_FAILED',e);
  }
}

export function* getUserLogout() {
  try {
    const resp = yield call(API.logoutUser);
    if (resp.status) {
      if (resp.status < 400) {
        yield put({
          type: GET_USER_LOGOUT_SUCCEEDED,
          resp,
        });
      } else {
        yield put({
          type: GET_USER_LOGOUT_FAILED,
          resp,
        });
      }
      yield put(mkUserLoggedOut());
    }
  } catch (e) {
    yield put({
      type: GET_USER_LOGOUT_FAILED,
      message: e.message,
    });
  }
}

function* userLoggedOut() {
  try {
    removeJWT(JWT_TOKEN);
    removeJWT(JWT_REFRESH);
    removeJWT('class-selected');
    API.removeAccessToken();

    yield put({
      type: USER_LOGGED_OUT_SUCCEEDED,
    });
  } catch (e) {
    console.log(e);
  }
}

export function* fetchQuestion(action) {
  const { configId } = action;
  try {
    const selectedClass = yield select(getSelectedClass);
    const resp = yield call(API.fetchQuestion, configId, selectedClass.code);
    const question = {};
    if (resp.status < 400) {
      if (resp.data.question) {
        question.question = b64DecodeUnicode(resp.data.question);
      }
      if (resp.data.answer_type) {
        question.answer_type = b64DecodeUnicode(resp.data.answer_type);
      }
      if (resp.data.solution) {
        question.solution = b64DecodeUnicode(resp.data.solution);
      }
      question.stuck_students = resp.data.stuck_students;
      if(resp.data.encounter_students) question.encounter_students = resp.data.encounter_students;

      yield put({
        type: GET_QUESTION_SUCCEEDED,
        data: camelize(question),
        configId,
      });
    } else {
      yield put({
        type: GET_QUESTION_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_QUESTION_FAILED,
      message: e.message,
    });
  }
}

export function* fetchSyllabus(action) {
  try {
    const { chapterId } = action;
    const resp = yield call(API.fetchTopicSyllabus, chapterId);
    if(resp.status < 400) {
      yield put({
        type: GET_SYLLABUS_SUCCEEDED,
        data: camelize(resp.data),
        chapterId
      })
    }
    else {
      yield put({
        type: GET_SYLLABUS_FAILED,
        resp,
      })
    }
  }
  catch(e) {
    console.log(e);
    yield put({
      type: GET_SYLLABUS_FAILED,
      message: e.message
    })
  }
}

export function* fetchQuestionMarkup(action) {
  const selectedTopicLanguage = yield select(makeGetSelectedTopicLanguage());
  try {
    const resp = yield call(API.fetchConfigTypeInfo, action, selectedTopicLanguage);
    if(resp.status < 400) {
      yield put({
        type: CONFIG_DATA_SUCCEEDED,
        data: camelize(resp.data),
        topicId: action.topicId,
        topicLevel: action.topicLevel
      })
    }
    else {
      yield put({
        type: CONFIG_DATA_FAILED,
        resp,
      })
    }
  }
  catch(e) {
    yield put({
      type: CONFIG_DATA_FAILED,
      message: e.message
    })
  }
}

export function* publishTeacherTopic(action) {
  try {
    const {classCode,publishStatus,chapterId,topicId,topicIndex} = action;
    const resp = yield call(API.publishTeacherTopic,classCode,publishStatus,topicId);
    if(resp.status < 400) {
      const chapterView = yield select(getChapterView)
      const classId = yield select(getSelectedClass)
      const chapterIndex = chapterView[classId.id].findIndex(chapter => chapter.id == chapterId);
      yield put({
        type:PUBLISH_TEACHER_TOPIC_SUCCEDDED,
        classCodeId:classId.id,
        publishStatus,
        chapterIndex,
        topicIndex,
      })

    }
    else {
      yield put({
        type: PUBLISH_TEACHER_TOPIC_FAILED,
        resp,
      })
    }
  }
  catch(e){
    yield put({
      type: PUBLISH_TEACHER_TOPIC_FAILED,
      message:e.message
    })
  }
}

export function* fetchContentInfo(action) {
  try {
    const resp = yield call(API.fetchQuestionContentInfo, action.contentId);
    if(resp.status < 400) {
      let content = b64DecodeUnicode(resp.data.data)
      yield put({
        type: GET_QUESTION_CONTENT_SUCCEEDED,
        data: content,
        contentId: action.contentId,
      })
    }
    else {
      yield put({
        type: GET_QUESTION_CONTENT_FAILED,
        resp,
      })
    }
  }
  catch(e) {
    yield put({
      type: GET_QUESTION_CONTENT_FAILED,
      message: e.message
    })
  }
}

export function* getBrokenDown(action) {
  const selectedTopicLanguage = yield select(makeGetSelectedTopicLanguage());
  try {
    const { configId, quesId } = action
    const resp = yield call(API.fetchBrokenDown, configId, quesId, selectedTopicLanguage)
    if(resp.status < 400) {
      let questionArr = resp.data.map(questionObj => {
        let newQues = {...questionObj}
        newQues.question = b64DecodeUnicode(newQues.question)
        newQues.solution = b64DecodeUnicode(newQues.solution)
        newQues.answer_type = b64DecodeUnicode(newQues.answer_type)
        newQues.hint = b64DecodeUnicode(newQues.hint)
        return newQues
      })
      yield put({
        type: GET_BROKEN_DOWN_SUCCEEDED,
        quesId,
        data: camelize(questionArr),
      })
    }
    else {
      yield put({
        type: GET_BROKEN_DOWN_FAILED,
        resp
      })
    }
  }
  catch(e) {
    yield put({
      type: GET_BROKEN_DOWN_FAILED,
      message: e.message
    })
  }
}

export function* registerClientUser(action) {
  try {
    const {userObj} = action
    const resp = yield call(API.registerClientUser, userObj)
    if(resp.status < 400) {
      yield put({
        type:REGISTER_CLIENT_USER_SUCCEEDED,
        userObj,
      })
    }
    else yield put({
      type: REGISTER_CLIENT_USER_FAILED,
      resp
    })
  } catch(e) {
    yield put({
      type: REGISTER_CLIENT_USER_FAILED,
      message: e.message
    })
  }
}

export function* resendClientMail(action) {
  try {
    const clientUserName = yield select(getClientUserName)
    const payload = {
      username: clientUserName
    }
    const resp = yield call(API.resendClientMail, payload)
    if(resp.status < 400) yield put({
      type: CLIENT_MAIL_RESEND_SUCCEEDED
    })
    else yield put({
      type: CLIENT_MAIL_RESEND_FAILED,
      resp
    })
  } catch(e) {
    yield put({
      type: CLIENT_MAIL_RESEND_FAILED,
      message: e.message
    })
  }
}

function* registerNewStudent(action) {
  try {
    let newStudent;
    newStudent = action.force
      ? yield select(makeGetStudentRegistrationData)
      : action.newStudent;
		const resp = yield call(API.registerNewStudent, newStudent, action.force);
		if (resp.status >= 400) {
			yield put({
				type: REGISTER_NEW_STUDENT_FAILED,
				data: camelize(resp.data),
			});
		} else {
			yield put({
				type: REGISTER_NEW_STUDENT_SUCCEEDED,
				data: camelize(resp.data),
			});
		}
	} catch (e) {
		yield put({
			type: REGISTER_NEW_STUDENT_FAILED,
			message: e.message,
		});
	}
}

function* checkStudentExistence(action) {
  try {
    const resp = yield call(API.checkIfStudentExists,action.email,action.classCode);
    if (resp.status >= 400) {
      yield put({
        type: CHECK_STUDENT_EXISTS_DATA,
        resp
      })
    } else {
      yield put({
        type: CHECK_STUDENT_EXISTS_DATA,
        resp
      })
    }
  } catch (e) {
    yield put({
      type: CHECK_STUDENT_EXISTS_DATA,
      resp
    })
  }
}

function* checkTeacherExistence(action) {
  try {
    const resp = yield call(API.checkIfTeacherExists,action.email,action.classCode);
    if (resp.status >= 400) {
      yield put({
        type: CHECK_TEACHER_EXISTS_DATA,
        resp
      })
    } else {
      yield put({
        type: CHECK_TEACHER_EXISTS_DATA,
        resp
      })
    }
  } catch (e) {
    yield put({
      type: CHECK_TEACHER_EXISTS_DATA,
      error: e.message
    })
  }
}

function* getClassTeachers(action) {
  try {
    const resp = yield call(API.fetchClassTeachers, action.classCode);
    if (resp.status < 400) {
      yield put({
        type: GET_CLASS_TEACHERS_SUCCEEDED,
        data: camelize(resp.data),
      });
    } else {
      yield put({
        type: GET_CLASS_TEACHERS_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_CLASS_TEACHERS_FAILED,
      message: e.message,
    });
  }
}

function* removeClassTeachers(action) {
  try {
    const resp = yield call(API.removeClassTeachers, action.classCode, action.teacherId);
    if (resp.status < 400) {
      yield put({
        type: REMOVE_CLASS_TEACHERS_SUCCEEDED,
        data: camelize(resp.data),
        teacherId: action.teacherId,
      });
    } else {
      yield put({
        type: REMOVE_CLASS_TEACHERS_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: REMOVE_CLASS_TEACHERS_FAILED,
      message: e.message,
    });
  }
}
function* getSearchedTeachers(action) {
  try {
    const resp = yield call(API.fetchSearchedTeachers, action.searchText,action.classCode);
    if (resp.status < 400) {
      yield put({
        type: GET_SEARCH_TEACHERS_SUCCEEDED,
        data: camelize(resp.data),
      });
    } else {
      yield put({
        type: GET_SEARCH_TEACHERS_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_SEARCH_TEACHERS_FAILED,
      message: e.message,
    });
  }
}

export function* bulkStudentRegistration(action) {
  try {
    const resp = yield call(API.bulkRegisterStudents, action.payload)
    if(resp.status < 400) {
      yield put({
        type: BULK_REGISTER_STUDENTS_SUCCEEDED,
        resp
      })
    }
    else yield put({
      type: BULK_REGISTER_STUDENTS_FAILED,
      resp
    })
  } catch(e) {
    yield put({
      type: BULK_REGISTER_STUDENTS_FAILED,
      message: e.message
    })
  }
}

export function* bulkTeacherRegistration(action) {
  try {
    const resp = yield call(API.bulkRegisterTeachers, action.payload)
    if(resp.status < 400) {
      yield put({
        type: BULK_REGISTER_TEACHERS_SUCCEEDED,
        resp
      })
    }
    else yield put({
      type: BULK_REGISTER_TEACHERS_FAILED,
      resp
    })
  } catch(e) {
    yield put({
      type: BULK_REGISTER_TEACHERS_FAILED,
      message: e.message
    })
  }
}

export function* watchGetTopicLaunchSettings(payload) {
  try {
    const resp = yield call(API.fetchTopicLaunchSettings, payload.classId, payload.topicId);
    // console.log(resp)
    if (resp.status < 400) {
      yield put({
        type: GET_TOPIC_LAUNCH_SETTINGS_SUCCEEDED,
        resp
      });
    } else {
      yield put({
        type: GET_TOPIC_LAUNCH_SETTINGS_FAILED,
        resp,
      });
    }
  } catch (e) {
    console.log(e)
    yield put({
      type: GET_TOPIC_LAUNCH_SETTINGS_FAILED,
      message: e.message,
    });
  }
}

export function* watchSetTopicLaunchSettings(payload) {
  try {
    const resp = yield call(API.changeTopicLaunchSettings, payload.data,payload.topicId)
    if(resp.status < 400) {
      // console.log(resp)
      yield put({
        type: SET_TOPIC_LAUNCH_SETTINGS_SUCCEEDED,
        resp
      })
    }
    else yield put({
      type: SET_TOPIC_LAUNCH_SETTINGS_FAILED,
      resp
    })
  } catch(e) {
    yield put({
      type: SET_TOPIC_LAUNCH_SETTINGS_FAILED,
      message: e.message
    })
  }
}

export function* watchGetAssessmentLaunchSettings(payload) {
  try {
    const resp = yield call(API.fetchAssessmentLaunchSettings, payload.classId, payload.assessmentId);
    if (resp.status < 400) {
      yield put({
        type: GET_ASSESSMENT_LAUNCH_SETTINGS_SUCCEEDED,
        resp
      });
    } else {
      yield put({
        type: GET_ASSESSMENT_LAUNCH_SETTINGS_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_ASSESSMENT_LAUNCH_SETTINGS_FAILED,
      message: e.message,
    });
  }
}

export function* watchSetAssessmentLaunchSettings(payload) {
  try {
    const newPayload = {
      classId: JSON.parse(JSON.stringify(payload.data.data.class_code)),
      assessmentId: JSON.parse(JSON.stringify(payload.data.assessmentId))
    }
    const resp = yield call(API.changeAssessmentLaunchSettings, payload.data, payload.assessmentId)
    if(resp.status < 400) {
      yield put({
        type: SET_ASSESSMENT_LAUNCH_SETTINGS_SUCCEEDED,
        resp
      })
    }
    else if(resp.status == 400){
      yield put({
        type: SET_ASSESSMENT_LAUNCH_SETTINGS_FAILED,
        resp
      })
      try {
        const resp = yield call(API.fetchAssessmentLaunchSettings, newPayload)
        if (resp.status < 400) {
          yield put({
            type: GET_ASSESSMENT_LAUNCH_SETTINGS_SUCCEEDED,
            resp
          });
        } else {
          yield put({
            type: GET_ASSESSMENT_LAUNCH_SETTINGS_FAILED,
            resp,
          });
        }
      } catch (e) {
        yield put({
          type: GET_ASSESSMENT_LAUNCH_SETTINGS_FAILED,
          message: e.message,
        });
      }
    }
    else yield put({
      type: SET_ASSESSMENT_LAUNCH_SETTINGS_FAILED,
      resp
    })
  } catch(e) {
    yield put({
      type: SET_ASSESSMENT_LAUNCH_SETTINGS_FAILED,
      message: e.message
    })
  }
}

export function* watchAddQuestionBookmark(payload) {
  try {
    const resp = yield call(API.addRemoveBookmarkQuestion, payload);
    // console.log(resp)
    if (resp.status < 400) {
      yield put({
        type: ADD_QUESTION_TO_BOOKMARK_DATA_SUCCEEDED,
        resp
      });
      yield put({
        type: GET_CLASS_BOOKMARKS_DATA,
        payload: {
          "classCode": payload.payload.classCode
        }
      });
    } else {
      yield put({
        type: ADD_QUESTION_TO_BOOKMARK_DATA_FAILED,
        resp,
      });
    }
  } catch (e) {
    console.log(e)
    yield put({
      type: ADD_QUESTION_TO_BOOKMARK_DATA_FAILED,
      message: e.message,
    });
  }
}

export function* watchRemoveQuestionBookmark(payload) {
  try {
    const resp = yield call(API.addRemoveBookmarkQuestion, payload);
    // console.log(resp)
    if (resp.status < 400) {
      yield put({
        type: REMOVE_QUESTION_FROM_BOOKMARK_DATA_SUCCEEDED,
        resp
      });
      yield put({
        type: GET_CLASS_BOOKMARKS_DATA,
        payload: {
          "classCode": payload.payload.classCode
        }
      });
    } else {
      yield put({
        type: REMOVE_QUESTION_FROM_BOOKMARK_DATA_FAILED,
        resp,
      });
    }
  } catch (e) {
    console.log(e)
    yield put({
      type: REMOVE_QUESTION_FROM_BOOKMARK_DATA_FAILED,
      message: e.message,
    });
  }
}

export function* watchUpdateBookmark(payload) {
  try {
    const resp = yield call(API.updateBookmark, payload);
    // console.log(resp)
    if (resp.status < 400) {
      yield put({
        type: UPDATE_BOOKMARK_DATA_SUCCEEDED,
        resp,
        payload
      });
      if("added_in_assessment_request" in payload.payload){
        yield put({
          type: GET_CLASS_BOOKMARKS_DATA,
          payload: {
            "classCode": payload.payload.classCode
          }
        });
      }
    } else {
      yield put({
        type: UPDATE_BOOKMARK_DATA_FAILED,
        resp,
      });
    }
  } catch (e) {
    console.log(e)
    yield put({
      type: UPDATE_BOOKMARK_DATA_FAILED,
      message: e.message,
    });
  }
}

export function* watchUpdateAssessmentDraft(payload) {
  try {
    const resp = yield call(API.updateAssessmentDraft, payload);
    // console.log(resp)
    if (resp.status < 400) {
      yield put({
        type: UPDATE_ASSESSMENT_DRAFT_DATA_SUCCEEDED,
        resp,
        payload
      });
      // if("added_in_assessment_request" in payload.payload){
        yield put({
          type: GET_ASSESSMENT_DRAFT_DATA,
          payload: {
            "classCode": payload.payload.classCode
          }
        });
      // }
    } else {
      yield put({
        type: UPDATE_ASSESSMENT_DRAFT_DATA_FAILED,
        resp,
      });
    }
  } catch (e) {
    console.log(e)
    yield put({
      type: UPDATE_ASSESSMENT_DRAFT_DATA_FAILED,
      message: e.message,
    });
  }
}

export function* watchFetchClassBookmarksData(payload) {
  try {
    const resp = yield call(API.fetchClassBookmarks, payload);
    // console.log(resp)
    if (resp.status < 400) {
      yield put({
        type: GET_CLASS_BOOKMARKS_DATA_SUCCEEDED,
        resp
      });
    } else {
      yield put({
        type: GET_CLASS_BOOKMARKS_DATA_FAILED,
        resp,
      });
    }
  } catch (e) {
    console.log(e)
    yield put({
      type: GET_CLASS_BOOKMARKS_DATA_FAILED,
      message: e.message,
    });
  }
}

export function* watchFetchAssessmentDraftData(payload) {
  try {
    const resp = yield call(API.fetchAssessmentDraft, payload);
    // console.log(resp)
    if (resp.status < 400) {
      yield put({
        type: GET_ASSESSMENT_DRAFT_DATA_SUCCEEDED,
        resp
      });
    } else {
      yield put({
        type: GET_ASSESSMENT_DRAFT_DATA_FAILED,
        resp,
      });
    }
  } catch (e) {
    console.log(e)
    yield put({
      type: GET_ASSESSMENT_DRAFT_DATA_FAILED,
      message: e.message,
    });
  }
}

export function* watchSubmitAssessmentRequest(payload) {
  try {
    const resp = yield call(API.submitAssessmentRequest, payload);
    // console.log(resp)
    if (resp.status < 400) {
      yield put({
        type: SUBMIT_ASSESSMENT_REQUEST_SUCCEEDED,
        resp
      });
    } else {
      yield put({
        type: SUBMIT_ASSESSMENT_REQUEST_FAILED,
        resp,
      });
    }
  } catch (e) {
    console.log(e)
    yield put({
      type: SUBMIT_ASSESSMENT_REQUEST_FAILED,
      message: e.message,
    });
  }
}

export function* watchPublishAssessmentRequest(payload) {
  try {
    const resp = yield call(API.publishAssessment, payload);
    // console.log(resp)
    if (resp.status < 400) {
      yield put({
        type: PUBLISH_ASSESSMENT_REQUEST_SUCCEEDED,
        resp
      });
    } else {
      yield put({
        type: PUBLISH_ASSESSMENT_REQUEST_FAILED,
        resp,
      });
    }
  } catch (e) {
    console.log(e)
    yield put({
      type: PUBLISH_ASSESSMENT_REQUEST_FAILED,
      message: e.message,
    });
  }
}

export function* watchFetchAssessmentRequests(action) {
  try {
    let classCode = undefined;
    let testId = undefined;
    if(typeof action.classCode == "object"){
      classCode = action.classCode.classCode
      testId = action.classCode.testId
    }
    else{
      classCode = action.classCode
    }
    // console.log(typeof action.classCode)
    // const classCode = action.classCode;
    const resp = yield call(API.fetchAssessmentRequests, classCode);
    if (resp.status < 400) {
      const testResp = camelize(resp.data);
      let resultTestObj = {};
      if (testResp && testResp.length > 0) {
        for (let i = 0; i < testResp.length; i++) {
          if(!!testId){
            if(testId==testResp[i].id){
              yield put({
                type: SET_ACTIVE_VIEW_DRAFT,
                payload: testResp[i],
              });
            }
            // testId == testResp[i].id
          }
          const [month, year] = testResp[i].date;
          const key = `${month}_${year}`;
          resultTestObj[key] = resultTestObj[key] || [];
          resultTestObj[key].push(testResp[i]);
        }
      }
      yield put({
        type: FETCH_ASSESSMENT_REQUESTS_SUCCEEDED,
        data: resultTestObj,
        classCode,
      });
    } else {
      yield put({
        type: FETCH_ASSESSMENT_REQUESTS_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_ASSESSMENT_REQUESTS_FAILED,
      message: e.message,
    });
  }
}

function* getLeaderBoard(action) {
  try {
    const resp = yield call(API.leaderboard, action.classCode);
    if(resp.status < 400) {
      yield put({
        type: GET_LEADERBOARD_SUCCEEDED,
        data: resp.data
      });
    }
    else {
      yield put({
        type: GET_LEADERBOARD_FAILED,
        data: resp.data
      });
      yield put(mkInitApp());
    }
  }
  catch(e) {
    console.error(e,"THIS IS ERROR")
    yield put({
      type: GET_LEADERBOARD_FAILED,
      data: e.message
    });
  }
}

function* getLeaderBoardData(action) {
  try {
    const resp = yield call(API.leaderboardData, action.classCode);
    if(resp.status < 400) {
      yield put({
        type: GET_LEADERBOARD_DATA_SUCCEEDED,
        data: resp.data
      });
      yield put(mkSetSyncLeaderBoardDataLoader(false));
    }
    else {
      yield put({
        type: GET_LEADERBOARD_DATA_FAILED,
        data: resp.data
      });
      yield put(mkInitApp());
    }
  }
  catch(e) {
    yield put({
      type: GET_LEADERBOARD_DATA_FAILED,
      data: e.message
    });
  }
}


function* getMyLeaderBoardData(action) {
  console.log(2231)
  try {
    const resp = yield call(API.myleaderboard, action.classCode);
    if(resp.status < 400) {
      yield put({
        type: GET_MYLEADERBOARD_SUCCEEDED,
        data: resp.data
      });
    }
    else {
      yield put({
        type: GET_MYLEADERBOARD_FAILED,
        data: resp.data
      });
      yield put(mkInitApp());
    }
  }
  catch(e) {
    console.error(e,"THIS IS ERROR")
    yield put({
      type: GET_MYLEADERBOARD_FAILED,
      data: e.message
    });
  }
}
function* getToggleLeaderBoard(action) {
  try {
    
    console.log(":******************************");
    console.log(API.activateLeaderboard);
    console.log(action.classCode);

    const resp = yield call(API.activateLeaderboard, action.classCode);
    
    if(resp.status <400) {
      yield put({
        type: GET_TOGGLE_LEADERBOARD_SUCCEEDED,
        data: resp
      });
      yield put(mkGetLeaderBoardData(action.classCode));
    }
    else {
      yield put({
        type: GET_TOGGLE_LEADERBOARD_FAILED,
        data: resp.data
      });
      yield put(mkInitApp());
    }
  }
  catch(e) {
    console.error(e,"THIS IS ERROR")
    yield put({
      type: GET_TOGGLE_LEADERBOARD_FAILED,
      data: e.message
    });
  }
}

function* getLeaderBoardStudent(action) {
  try {
    const resp = yield call(API.getstudentLeaderBoard, action.classCode);
    if(resp.status < 400) {
      yield put({
        type: GET_LEADERBOARD_STUDENT_SUCCEEDED,
        data: resp.data
      });
    }
    else {
      yield put({
        type: GET_LEADERBOARD_STUDENT_FAILED,
        data: resp.data
      });
      yield put(mkInitApp());
    }
  }
  catch(e) {
    console.error(e,"THIS IS ERROR")
    yield put({
      type: GET_LEADERBOARD_STUDENT_FAILED,
      data: e.message
    });
  }
}


function* addStrudentInClass(action) {
  try {
    const resp = yield call(API.addStudentInClass, action.classCode,action.data);
    if(resp.status < 400)
    {
      const leaderboardData = yield select(getLeaderBoardStudentDataSelector);
      const requiredGroup = leaderboardData.find(i=>i.id === action.data[0].group_id);
      yield put(updateSingleStudentNinjaTeam(action.data[0].user_id,action.classCode,requiredGroup.id,requiredGroup.group_name));
      yield put(getLeaderBoardStudentData(action.classCode));
    }
  }
  catch(e) {
    console.error(e,"THIS IS ERROR")
  }
}

function* addStudentDevice(action) {
  // try {
  //   const jwtToken = getSavedJWT('cerebry-jwt-token');
  //   const resp = yield call(API.studentRegisteredDevice, ('jwt ' + jwtToken));
  //   yield put({
  //     type: STUDENT_REGISTERED_DEVICES_SUCCESS,
  //     data: resp.data
  //   });
  // }
  // catch(e) {
  //   console.error(e,"THIS IS ERROR")
  //   yield put({
  //     type: STUDENT_REGISTERED_DEVICES_FAILED,
  //     data: e.message
  //   });
  // }
  try {
    const resp = yield call(API.studentRegisteredDevice, action.id);
    if(resp.status < 400) {
      yield put({
        type: STUDENT_REGISTERED_DEVICES_SUCCESS,
        resp
      });
    }
    else {
      yield put({
        type: STUDENT_REGISTERED_DEVICES_FAILED,
        resp
      });
    }
  }
  catch(e) {
    yield put({
      type: STUDENT_REGISTERED_DEVICES_FAILED,
      message: e.message
    });
  }
}

function* fetchDateFilter(action) {
  try {
    const resp = yield call(API.fetchDateFilter)
    if(resp.status < 400) {
      yield put({
        type: FETCH_DATE_FILTERS_SUCCEEDED,
        data: camelize(resp.data),
      });
      yield put(mkGetNotificationAlert(undefined, resp.data[0].start_date, resp.data[0].end_date))
    }
    else {
      yield put({
        type: FETCH_DATE_FILTERS_FAILED,
        resp,
      });
    }
  } catch(e) {
    yield put({
      type: FETCH_DATE_FILTERS_FAILED,
      message: e.message,
    });
  }
}

function* getNotificationAlert(action) {
  try {
    const classes = yield select(getSelectedClass);
    const resp = yield call(API.getNotificationAlert, classes.code, action.userIds, action.startDate, action.endDate);
    if(resp.status < 400) {
      yield put({
        type: GET_ALERT_SUCCEEDED,
        data: camelize(resp.data),
        classCode: classes.code
      });
    }
    else {
      yield put({
        type: GET_ALERT_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_ALERT_FAILED,
      message: e.message,
    });
  }
}

function* getNotificationSession(action) {
  try {
    const classes = yield select(getSelectedClass);
    const resp = yield call(API.getNotificationSession, classes.code, action.userId, action.notificationId, action.startDate, action.endDate);
    if(resp.status < 400) {
      
      
      yield put({
        type: GET_NOTIFICATION_SESSION_SUCCEEDED,
        data: camelize(resp.data),
        classCode: classes.code,
        userId: action.userId
      });
    
    }
    else {
      yield put({
        type: GET_NOTIFICATION_SESSION_FAILED,
        resp,
      });
    }
  } catch (e) {
    console.log(e)
    yield put({
      type: GET_NOTIFICATION_SESSION_FAILED,
      message: e.message,
    });
  }
}

export function* getAttemptsNew(action) {
  const { studentId, topicId } = action;
  try {
    const resp = yield call(API.getAttemptsNew,  studentId,topicId);

    if (resp.status < 400) {
      
      const attemptsNewArray = [];
     
      const attempts = resp.data.attemtps;
      for (let i = 0; i < attempts.length; i++) {
        
        const attempt = attempts[i];
       
        const questionId = attempt.question_id;
        const decodedQuestion = b64DecodeUnicode(attempt.question);
        const decodedSolution = b64DecodeUnicode(attempt.solution);
        const decodedHint = b64DecodeUnicode(attempt.hint);
        const decodedAnswerType = b64DecodeUnicode(attempt.answer_type);
          
      
        //attemptsNewArray.push({   questionId: questionId, question:camelize (decodedQuestion )})
        /*console.log(  { questionId: questionId, 
          question: (decodedQuestion ),
          solution:decodedSolution,
          hint:decodedHint, 
          answerType:decodedAnswerType ,
          submittedAnswer:attempt.submitted_answer,
          solve:attempt.solve,
          uuid:attempt.uuid,
          validResponse:attempt.valid_response
        })*/
        attemptsNewArray.push( { questionId: questionId, 
          question: (decodedQuestion ),
          solution:decodedSolution,
          hint:decodedHint, 
          answerType:decodedAnswerType ,
          submittedAnswer:attempt.submitted_answer,
          solve:attempt.solve,
          uuid:attempt.uuid,
          validResponse:attempt.valid_response
        })

        
      }
    
      yield put({
        type: GET_ATTEMTPS_NEW_SUCCEDED,
        attemptsNew:attemptsNewArray,
      });
     
    } else {
      yield put({
        type: GET_ATTEMTPS_NEW_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_ATTEMTPS_NEW_FAILED,
      resp: e, // You might want to handle errors differently, e.g., pass the error object.
    });
  }
}

function* getQuestionAttempts(action) {
  const { configId, userId } = action;
  try {
    const resp = yield call(API.getQuestionAttempts, configId, userId);
    if (resp.status < 400) {
      yield put({
        type: GET_QUESTION_ATTEMPTS_SUCCEEDED,
        studentAttempt: resp.data,
      });
      for(let i=0; i<resp.data.length; i++) {
        const ques = resp.data[i]
        if(ques.hasOwnProperty("broken_down_concepts")) {
          const brokenList = Object.values(ques.broken_down_concepts)
          for(let j=0; j<brokenList.length; j++) {
            const brokenQues = brokenList[j]
            if(brokenQues.question) {
              const brokenData = {...brokenQues}
              brokenData.question = b64DecodeUnicode(brokenData.question);
              yield put({
                type: FETCH_QUESTION_HISTORY_SUCCEDED,
                data: camelize(brokenData),
                questionId: brokenData.id,
              });
            }
          }
        }
      }
      const quesData = resp.data[resp.data.length-1]
      if(!quesData.valid_response) {
        quesData.question = b64DecodeUnicode(quesData.question);
        yield put({
          type: FETCH_QUESTION_HISTORY_SUCCEDED,
          data: camelize(quesData),
          questionId: quesData.id,
        });
      }
    } else {
      yield put({
        type: GET_QUESTION_ATTEMPTS_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_QUESTION_ATTEMPTS_FAILED,
      message: e.message,
    });
  }
}

function* getConnectGoogleClass(action) {
  try {
    const resp = yield call(API.getConnectGoogleClass);
    if (resp.status < 400) {
      const urlParams = new URLSearchParams(resp.data.redirect_url);
      window.location.href = resp.data.redirect_url
    } else {
      yield put({
        type: GET_CONNECT_GOOGLECLASS_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_CONNECT_GOOGLECLASS_FAILED,
      message: e.message,
    });
  }
}

function* getSwitchGoogleAccount(action) {
  try {
    const resp = yield call(API.getSwitchGoogleAccount,action.code);
    if (resp.status < 400) {
      const urlParams = new URLSearchParams(resp.data.redirect_url);
      window.location.href = resp.data.redirect_url
    } else {
      yield put({
        type: GET_SWITCH_GOOGLEACCOUNT_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_SWITCH_GOOGLEACCOUNT_FAILED,
      message: e.message,
    });
  }
}

function* getTeacherGoogleAccountLogin(action) {
  try {
    const resp = yield call(API.getTeacherGoogleAccountLogin);
    if (resp.status < 400) {
      const urlParams = new URLSearchParams(resp.data.redirect_url);
      window.location.href = resp.data.redirect_url
    } else {
      yield put({
        type: GET_TEACHER_GOOGLELOGIN_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_TEACHER_GOOGLELOGIN_FAILED,
      message: e.message,
    });
  }
}

function* getTeacherGoogleAccountSignup(action) {
  try {
    const resp = yield call(API.getTeacherGoogleAccountSignup);
    if (resp.status < 400) {
      const urlParams = new URLSearchParams(resp.data.redirect_url);
      window.location.href = resp.data.redirect_url
    } else {
      yield put({
        type: GET_TEACHER_GOOGLESIGNUP_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_TEACHER_GOOGLESIGNUP_FAILED,
      message: e.message,
    });
  }
}

function* getGoogleClasses(action) {
  try {
    const classCode = yield select(getSelectedClass)
    const resp = yield call(API.getGoogleClasses, classCode.code);
    if (resp.status < 400) {
      yield put({
        type: GET_GOOGLECLASSES_SUCCEEDED,
        data: camelize(resp.data),
        classCode: classCode.code
      })
    } else {
      yield put({
        type: GET_GOOGLECLASSES_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_GOOGLECLASSES_FAILED,
      message: e.message,
    });
  }
}

function* getGoogleClassesOnboarding(action) {
  try {
    const resp = yield call(API.getGoogleClassesOnboarding);
    if (resp.status < 400) {
      yield put({
        type: GET_GOOGLECLASSESONBOARDING_SUCCEEDED,
        data: camelize(resp.data),
      })
    } else {
      yield put({
        type: GET_GOOGLECLASSESONBOARDING_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_GOOGLECLASSESONBOARDING_FAILED,
      message: e.message,
    });
  }
}

function* getGoogleClassroomStudents(action) {
  try {
    const resp = yield call(API.getGoogleClassroomStudents, action.code);
    if (resp.status < 400) {
      yield put({
        type: GET_GOOGLE_CLASSROOM_STUDENTS_SUCCEEDED,
        data: camelize(resp.data),
      })
    } else {
      yield put({
        type: GET_GOOGLE_CLASSROOM_STUDENTS_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_GOOGLE_CLASSROOM_STUDENTS_FAILED,
      message: e.message,
    });
  }
}

function* syncGoogleClassroomStudents(action) {
  const params = {};
  params.code = action.code;
  params.payload = action.payload;
  try {
    const resp = yield call(API.syncGoogleClassroomStudents, params);
    if (resp.status < 400) {
      yield put({
        type: SYNC_GOOGLE_CLASSROOM_STUDENTS_SUCCEEDED,
        data: camelize(resp.data),
      })
    } else {
      yield put({
        type: SYNC_GOOGLE_CLASSROOM_STUDENTS_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: SYNC_GOOGLE_CLASSROOM_STUDENTS_FAILED,
      message: e.message,
    });
  }
}

function* getLinkGoogleClass(action) {
  try {
    const { googleName, googleCode } = action
    const classCode = yield select(getSelectedClass)
    const payLoad = {}
    payLoad.name = googleName
    payLoad.code = googleCode
    const resp = yield call(API.getLinkGoogleClass, classCode.code, payLoad);
    if (resp.status < 400) {
      yield put({
        type: GET_LINK_GOOGLECLASS_SUCCEEDED,
        data: camelize(resp.data)
      })
    } else {
      yield put({
        type: GET_LINK_GOOGLECLASS_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_LINK_GOOGLECLASS_FAILED,
      message: e.message,
    });
  }
}

function* getUnlinkGoogleClass(action) {
  try {
    const classCode = yield select(getSelectedClass)
    const resp = yield call(API.getUnlinkGoogleClass, classCode.code);
    if (resp.status < 400) {
      yield put({
        type: GET_UNLINK_GOOGLECLASS_SUCCEEDED,
        data: camelize(resp.data)
      })
    } else {
      yield put({
        type: GET_UNLINK_GOOGLECLASS_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_UNLINK_GOOGLECLASS_FAILED,
      message: e.message,
    });
  }
}

function* getAllAttempts(action) {
  try {
    const testId = yield select(getSelectedTestId())
    const resp = yield call(API.getAllAttempts, testId, action.userId);
    if (resp.status < 400) {
      yield put({
        type: GET_ALL_ATTEMPTS_SUCCEEDED,
        data: camelize(resp.data),
        testId,
        userId: action.userId
      })
    } else {
      yield put({
        type: GET_ALL_ATTEMPTS_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_ALL_ATTEMPTS_FAILED,
      message: e.message,
    });
  }
}

function* fetchAutoGradingCTS(action) {
  try {
    const resp = yield call(API.fetchAutoGradingCTS, action.examId, action.studentId);
    if (resp.status < 400) {
      yield put({
        type: FETCH_AUTO_GRADING_CTS_SUCCEEDED,
        data: camelize(resp.data),
      });
      yield put(mkFetchAutoGradingQuestions(camelize(resp.data),action.studentId));
    } else {
      yield put({
        type: FETCH_AUTO_GRADING_CTS_FAILED,
        resp,
      });
    }
  }
  catch(e) {
    yield put({
      type: FETCH_AUTO_GRADING_CTS_FAILED,
      message: e.message,
    });
  }
}

function* getAllAssessmentClasses(action) {
  try {
    const resp = yield call(API.getAllAssessmentClasses,action.offset,action.limit);
    if(resp.status<400)
    {
      yield put({
        type: GET_ALL_ASSESSMENT_CLASSES_SUCCEEDED,
        data: camelize(resp.data.results)
      })
    }
    else
    {
      yield put({
        type: GET_ALL_ASSESSMENT_CLASSES_FAILED,
        resp,
      });
    }
  }
  catch(e) {
    yield put({
      type: GET_ALL_ASSESSMENT_CLASSES_FAILED,
      message: e.message,
    });
  }
}

function* getFilteredAssessmentClasses(action) {
  try {
    yield put(mkSetLoadingFilteredClasses());
    const resp = yield call(API.getAllAssessmentClasses,null,null,action.query);
    if(resp.status<400)
    {
      yield put({
        type: GET_FILTERED_ASSESSMENT_CLASSES_SUCCEEDED,
        data: camelize(resp.data)
      })
    }
    else
    {
      yield put({
        type: GET_FILTERED_ASSESSMENT_CLASSES_FAILED,
        resp,
      });
    }
  }
  catch(e) {
    yield put({
      type: GET_FILTERED_ASSESSMENT_CLASSES_FAILED,
      message: e.message,
    });
  }
}

function* getAssessmentDraftClasses(action) {
  try {
    const resp = yield call(API.getAssessmentDraftClasses, action.draftId);
    if(resp.status<400)
    {
      yield put({
        type: GET_ASSESSMENT_DRAFT_CLASSES_SUCCEEDED,
        data: camelize(resp.data)
      })
    }
    else
    {
      yield put({
        type: GET_ASSESSMENT_DRAFT_CLASSES_FAILED,
        resp,
      });
    }
  }
  catch(e) {
    yield put({
      type: GET_ASSESSMENT_DRAFT_CLASSES_FAILED,
      message: e.message,
    });
  }
}

function* addAssessmentDraftClasses(action) {
  const payload = {};
  payload.classes = action.classes;
  payload.action = action.action;
  try {
    const resp = yield call(API.addAssessmentDraftClasses,action.draftId, payload);
    if (resp.status < 400) {
      yield put({
        type: ADD_ASSESSMENT_DRAFT_CLASSES_SUCCEEDED,
        data: camelize(resp.data),
      })
      yield put(mkGetAssessmentDraftClasses(action.draftId));
    } else {
      yield put({
        type: ADD_ASSESSMENT_DRAFT_CLASSES_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: ADD_ASSESSMENT_DRAFT_CLASSES_FAILED,
      message: e.message,
    });
  }
}

function* getAssessmentAddedClasses(action) {
  try {
    const resp = yield call(API.getAssessmentAddedClasses, action.assessmentId);
    if(resp.status<400)
    {
      yield put({
        type: GET_ASSESSMENT_ADDED_CLASSES_SUCCEEDED,
        data: camelize(resp.data)
      })
    }
    else
    {
      yield put({
        type: GET_ASSESSMENT_ADDED_CLASSES_FAILED,
        resp,
      });
    }
  }
  catch(e) {
    yield put({
      type: GET_ASSESSMENT_ADDED_CLASSES_FAILED,
      message: e.message,
    });
  }
}
function* getScreenErrorsMapping(action) {
  try {
    const resp = yield call(API.getScreenErrorsMapping);
    if(resp.status<400)
    {
      yield put({
        type: GET_SCREEN_ERRORS_MAPPING_SUCCEEDED,
        data: camelize(resp.data)
      })
    }
    else
    {
      yield put({
        type: GET_SCREEN_ERRORS_MAPPING_FAILED,
        resp,
      });
    }
  }
  catch(e) {
    yield put({
      type: GET_SCREEN_ERRORS_MAPPING_FAILED,
      message: e.message,
    });
  }

}

function* submitLoginErrorReport(action) {
  try {
    const resp = yield call(API.submitLoginErrorReport, action.username);
    if(resp.status<400)
    {
      yield put({
        type: SUBMIT_LOGIN_ERROR_REPORT_SUCCEEDED,
        data: camelize(resp.data)
      })
    }
    else
    {
      yield put({
        type: SUBMIT_LOGIN_ERROR_REPORT_FAILED,
        resp,
        error: resp.data.username && resp.data.username[0] || resp.data[0]
      });
    }
  }
  catch(e) {
    yield put({
      type: SUBMIT_LOGIN_ERROR_REPORT_FAILED,
      message: e.message,
    });
  }


}
function* submitErrorReport(action) {
  try {
    const resp = yield call(API.submitErrorReport, action.payload,action.classId);
    if(resp.status<400)
    {
      yield put({
        type: SUBMIT_ERROR_REPORT_SUCCEEDED,
        data: camelize(resp.data)
      })
    }
    else
    {
      yield put({
        type: SUBMIT_ERROR_REPORT_FAILED,
        resp,
      });
    }
  }
  catch(e) {
    yield put({
      type: SUBMIT_ERROR_REPORT_FAILED,
      message: e.message,
    });
  }
}


function* fetchCanvasClassList() {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const authTok = urlParams.get('auth-tok');

    if (authTok) {
      API.setAccessToken('jwt ' + authTok);
    } else {
      throw new Error('Auth token not found');
    }

    const resp = yield call(API.getCanvasClassListAPI);

    if (resp.status < 400) {
      yield put({
        type: GET_CANVAS_CLASS_LIST_SUCCESS,
        data: resp.data
      });
    }   else
    {
      yield put({
        type: GET_CANVAS_CLASS_LIST_FAILURE,
        resp,
      });
    }
  } catch (error) {
    yield put({
      type: GET_CANVAS_CLASS_LIST_FAILURE,
      message: error.message,
    });
  }
}

function* fetchCanvasChapterList(action) {

  try {

    const resp = yield call(API.getCanvasClassChapterData, action.classCode);

    if (resp.status < 400) {
      yield put({
        type: GET_CANVAS_CHAPTER_LIST_SUCCESS,
        classCode: action.classCode,
        data: resp.data
      });
    } else
    {
      yield put({
        type: GET_CANVAS_CHAPTER_LIST_FAILURE,
        resp,
      });
    }
  } catch (error) {
    yield put({
      type: GET_CANVAS_CHAPTER_LIST_FAILURE,
      message: error.message,
    });
  }
}


function* getAIFeedbackReport(action){
  const {config_id,studentId,attemptOrder} = action;
  try {
    const resp = yield call(API.getAIFeedbackReport,config_id,studentId,attemptOrder);
    if (resp.status < 400) {
      yield put({
        type: GET_AI_FEEDBACK_REPORT_SUCCEEDED,
        resp,
      });
    } else {
      yield put({
        type: GET_AI_FEEDBACK_REPORT_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_AI_FEEDBACK_REPORT_FAILED,
      message: e.message,
    });
  }
}

function* editAIFeedbackCriteria(action){
  const {criteriaId, marksObtained, feedback} = action;
  try {
    const resp = yield call(API.editAIFeedbackCriteria,criteriaId, marksObtained, feedback);
    if (resp.status < 400) {
      yield put({
        type: EDIT_AI_FEEDBACK_CRITERIA_SUCCEEDED,
        resp,
      });
      yield put(mkSetEditAIFeedbackCriteriaLoader(false));
    } else {
      yield put({
        type: EDIT_AI_FEEDBACK_CRITERIA_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: EDIT_AI_FEEDBACK_CRITERIA_FAILED,
      message: e.message,
    });
  }
}

function* editOpenEndedFeedback(action){
  const {assessmentStudentId, marksObtained, feedback, subpartNumber} = action;
  try {
    const resp = yield call(API.editOpenEndedFeedback,assessmentStudentId, marksObtained, feedback, subpartNumber);
    if (resp.status < 400) {
      yield put({
        type: EDIT_OPEN_ENDED_FEEDBACK_SUCCEEDED,
        resp,
      });
      yield put(mkSetEditOpenEndedFeedbackLoader(false));
    } else {
      yield put({
        type: EDIT_OPEN_ENDED_FEEDBACK_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: EDIT_OPEN_ENDED_FEEDBACK_FAILED,
      message: e.message,
    });
  }
}

function* resetMockTest(action){
  try {
    const resp = yield call(API.resetMockTest, action.examId, action.classCode, action.studentId, action.start_time, action.end_time);
    if(resp.status < 400) {
      yield put({
        type: MOCK_TEST_RESET_SUCCESS,
        resp
      });
    }
    else yield put({
      type: MOCK_TEST_RESET_FAILURE,
      resp
    });
  }
  catch(e) {
    yield put({
      type: MOCK_TEST_RESET_FAILURE,
      message: e.message
    });
  }
}

function* downloadTopicReport(action){
  try {
    const resp = yield call(API.downloadTopicReport, action.classCode, action.topicId);
    if(resp.status < 400) {
      yield put({
        type: DOWNLOAD_TOPIC_REPORT_SUCCESS,
        resp
      });
    }
    else yield put({
      type: DOWNLOAD_TOPIC_REPORT_FAILURE,
      resp
    });
  }
  catch(e) {
    yield put({
      type: DOWNLOAD_TOPIC_REPORT_FAILURE,
      message: e.message
    });
  }
}

function* downloadMockExamReport(action){
  try {
    const resp = yield call(API.downloadMockExamReport, action.classCode, action.examId);
    if(resp.status < 400) {
      yield put({
        type: DOWNLOAD_MOCK_EXAM_REPORT_SUCCESS,
        resp
      });
    }
    else yield put({
      type: DOWNLOAD_MOCK_EXAM_REPORT_FAILURE,
      resp
    });
  }
  catch(e) {
    yield put({
      type: DOWNLOAD_MOCK_EXAM_REPORT_FAILURE,
      message: e.message
    });
  }
}
function* getAIGradingFeedbackReport(action){
  const {config_id,studentId,attemptOrder} = action;
  try {
    const resp = yield call(API.getAIGradingFeedbackReport,config_id,studentId,attemptOrder);
    if (resp.status < 400) {
      yield put({
        type: GET_AI_GRADING_FEEDBACK_REPORT_SUCCEEDED,
        resp,
      });
    } else {
      yield put({
        type: GET_AI_GRADING_FEEDBACK_REPORT_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_AI_GRADING_FEEDBACK_REPORT_FAILED,
      message: e.message,
    });
  }
}

function* editAIGradingFeedback(action){
  const {feedback_id,marksObtained, feedback} = action;
  try {
    const resp = yield call(API.editAIGradingFeedback, feedback_id, marksObtained, feedback);
    if (resp.status < 400) {
      yield put({
        type: EDIT_AI_GRADING_FEEDBACK_SUCCEEDED,
        resp,
      });
    } else {
      yield put({
        type: EDIT_AI_GRADING_FEEDBACK_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: EDIT_AI_GRADING_FEEDBACK_FAILED,
      message: e.message,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(INIT_APP, initApp),
    takeLatest(GET_ME_REQUESTED, getMe),
    takeLatest(GET_USER_REFRESH_REQUESTED, getUserRefresh),
    takeLatest(USER_LOGGED_OUT, userLoggedOut),
    takeLatest(POST_NEW_USER_REQUESTED, registerUser),
    takeLatest(GET_USER_LOGIN_REQUESTED, loginUser),
    takeLatest(GET_USER_PASSWORD_LINK_REQUESTED, forgotPassword),
    takeLatest(VERIFY_RESET_CODE_REQUESTED, verifyResetCode),
    takeLatest(RESET_PASSWORD_REQUESTED, resetPassword),
    takeLatest(GET_SCHOOL_CODE_SUBMISSION_REQUESTED, submitCode),
    takeLatest(GET_SCHOOL_PIN_SUBMISSION_REQUESTED, submitPin),
    takeLatest(GET_SCHOOL_GRADES_REQUESTED, fetchGrades),
    takeLatest(GET_LEVEL_DROPDOWN_ITEMS_REQUESTED, fetchLevelDropdownItems),
    takeLatest(GET_LEVEL_SUBMISSION_REQUESTED, submitLevel),
    takeLatest(GET_MULTI_STUDENT_LEVEL_SUBMISSION_REQUESTED, submitMultiStudentLevel),
    takeLatest(GET_CHAPTERS_REQUESTED, fetchChapters),
    takeLatest(GET_CREATE_CLASS_REQUESTED, submitCreateClass),
    takeLatest(GET_STUDENT_EXCEL_UPLOAD_REQUESTED, uploadStudentExcel),
    takeLatest(GET_STUDENT_EXCEL_DOWNLOAD_REQUESTED, downloadStudentExcel),
    takeLatest(GET_CREATE_MULTI_CLASS_REQUESTED, submitCreateMultiClass),
    takeLatest(GET_PIN_SUBMIT_REQUESTED, submitClassPin),
    takeLatest(SELECT_CHAPTER_REQUESTED, selectChapter),
    takeLatest(GET_CONFIG_TYPE_REQUESTED, fetchConfigType),
    takeLatest(GET_STUDENT_REPORT_REQUESTED, fetchStudentReport),
    takeLatest(GET_EXAMS_QUESTIONS_REQUESTED, fetchExamQuestions),
    takeLatest(GET_TESTS_REQUESTED, fetchTests),
    takeLatest(SELECT_TEST_REQUESTED, selectTest),
    takeLatest(LAUNCH_EXAM_REQUESTED, launchExam),
    takeLatest(FETCH_EXAM_REPORT_REQUESTED, fetchExamReport),
    takeLatest(RESET_TEST_ATTEMPTS_REQUESTED, resetTestAttempts),
    takeLatest(GET_STUDENTS_REQUESTED, fetchStudents),
    takeLatest(DELETE_STUDENT_REQUESTED, removeStudent),
    takeLatest(FETCH_STUDENT_WISE_REPORT_REQUESTED, fetchStudentWiseReport),
    takeLatest(CREATE_ANNOUNCEMENT_REQUESTED, createAnnouncement),
    takeLatest(DELETE_ANNOUNCEMENT_REQUESTED, deleteAnnouncement),
    takeLatest(GET_MEETING_URL_REQUESTED, getMeetingUrl),
    takeLatest(GET_START_CONFERENCE_REQUESTED, startConference),
    takeLatest(FETCH_DATE_RANGE_REQUESTED, fetchDateRange),
    takeLatest(SELECT_ATTENDENCE_MONTH, fetchAttendenceMonth),
    takeLatest(GET_STUDENT_SESSION_REQUESTED, getStudentSession),
    takeLatest(FETCH_PRACTICED_REQUESTED, fetchPracticedQuestion),
    takeLatest(GET_STUDENT_STUCK_REQUESTED, getStudentStuck),
    takeLatest(FETCH_QUESTION_HISTORY_REQUESTED, fetchQuestionHistory),
    takeLatest(GET_CHANGE_PASSWORD_REQUESTED, changePassword),
    takeLatest(POST_REPORT_REQUESTED, report),
    takeLatest(GET_USER_LOGOUT_REQUESTED, getUserLogout),
    takeLatest(GET_QUESTION_REQUESTED, fetchQuestion),
    takeLatest(GET_SYLLABUS_REQUESTED,fetchSyllabus),
    takeLatest(CONFIG_DATA_REQUESTED,fetchQuestionMarkup),
    takeLatest(PUBLISH_TEACHER_TOPIC_REQUESTED,publishTeacherTopic),
    takeLatest(GET_QUESTION_CONTENT_REQUESTED,fetchContentInfo),
    takeLatest(GET_BROKEN_DOWN_REQUESTED, getBrokenDown),
    takeLatest(REGISTER_CLIENT_USER_REQUESTED,registerClientUser),
    takeLatest(CLIENT_MAIL_RESEND_REQUESTED, resendClientMail),
    takeLatest(REGISTER_NEW_STUDENT_REQUESTED, registerNewStudent),
    takeLatest(CHECK_STUDENT_EXISTS, checkStudentExistence),
    takeLatest(CHECK_TEACHER_EXISTS, checkTeacherExistence),
    takeLatest(GET_CLASS_TEACHERS_REQUESTED, getClassTeachers),
    takeLatest(REMOVE_CLASS_TEACHERS_REQUESTED, removeClassTeachers),
    takeLatest(GET_SEARCH_TEACHERS_REQUESTED, getSearchedTeachers),
    takeLatest(BULK_REGISTER_STUDENTS_REQUESTED, bulkStudentRegistration),
    takeLatest(BULK_REGISTER_TEACHERS_REQUESTED, bulkTeacherRegistration),
    takeLatest(GET_TOPIC_LAUNCH_SETTINGS_REQUESTED, watchGetTopicLaunchSettings),
    takeLatest(SET_TOPIC_LAUNCH_SETTINGS_REQUESTED, watchSetTopicLaunchSettings),
    takeLatest(GET_ASSESSMENT_LAUNCH_SETTINGS_REQUESTED, watchGetAssessmentLaunchSettings),
    takeLatest(SET_ASSESSMENT_LAUNCH_SETTINGS_REQUESTED, watchSetAssessmentLaunchSettings),
    takeLatest(ADD_QUESTION_TO_BOOKMARK_DATA, watchAddQuestionBookmark),
    takeLatest(REMOVE_QUESTION_FROM_BOOKMARK_DATA, watchRemoveQuestionBookmark),
    takeLatest(UPDATE_BOOKMARK_DATA, watchUpdateBookmark),
    takeLatest(GET_CLASS_BOOKMARKS_DATA, watchFetchClassBookmarksData),
    takeLatest(SUBMIT_ASSESSMENT_REQUEST, watchSubmitAssessmentRequest),
    takeLatest(PUBLISH_ASSESSMENT_REQUEST, watchPublishAssessmentRequest),
    takeLatest(FETCH_ASSESSMENT_REQUESTS, watchFetchAssessmentRequests),
    takeLatest(GET_ASSESSMENT_DRAFT_DATA, watchFetchAssessmentDraftData),
    takeLatest(UPDATE_ASSESSMENT_DRAFT_DATA, watchUpdateAssessmentDraft),
    takeLatest(GET_LEADERBOARD_REQUESTED,getLeaderBoard),
    takeLatest(GET_LEADERBOARD_DATA_REQUESTED,getLeaderBoardData),
    takeLatest(GET_MYLEADERBOARD_REQUESTED,getMyLeaderBoardData),
    takeLatest(GET_TOGGLE_LEADERBOARD_REQUESTED,getToggleLeaderBoard),
    takeLatest(GET_LEADERBOARD_STUDENT_REQUESTED,getLeaderBoardStudent),
    takeLatest(ADD_STUDENT_IN_CLASS_REQUESTED,addStrudentInClass),
    takeLatest(GET_MULTI_STUDENT_GROUP_SUBMISSION_REQUESTED,addMultiStudentInClassReq),
    takeLatest(STUDENT_REGISTERED_DEVICES,addStudentDevice),
    takeLatest(FETCH_DATE_FILTERS_REQUESTED,fetchDateFilter),
    takeLatest(GET_ALERT_REQUESTED,getNotificationAlert),
    takeLatest(GET_NOTIFICATION_SESSION_REQUESTED,getNotificationSession),
    takeLatest(GET_ATTEMTPS_NEW_REQUESTED,getAttemptsNew),
    takeLatest(GET_QUESTION_ATTEMPTS_REQUESTED, getQuestionAttempts),
    takeLatest(GET_CONNECT_GOOGLECLASS_REQUESTED, getConnectGoogleClass),
    takeLatest(GET_SWITCH_GOOGLEACCOUNT_REQUESTED, getSwitchGoogleAccount),
    takeLatest(GET_TEACHER_GOOGLELOGIN_REQUESTED, getTeacherGoogleAccountLogin),
    takeLatest(GET_TEACHER_GOOGLESIGNUP_REQUESTED, getTeacherGoogleAccountSignup),
    takeLatest(GET_GOOGLECLASSES_REQUESTED, getGoogleClasses),
    takeLatest(GET_GOOGLECLASSESONBOARDING_REQUESTED, getGoogleClassesOnboarding),
    takeLatest(GET_GOOGLE_CLASSROOM_STUDENTS_REQUESTED, getGoogleClassroomStudents), 
    takeLatest(SYNC_GOOGLE_CLASSROOM_STUDENTS_REQUESTED,syncGoogleClassroomStudents),
    takeLatest(GET_LINK_GOOGLECLASS_REQUESTED, getLinkGoogleClass),
    takeLatest(GET_UNLINK_GOOGLECLASS_REQUESTED, getUnlinkGoogleClass),
    takeLatest(GET_ALL_ATTEMPTS_REQUESTED, getAllAttempts),
    takeLatest(FETCH_AUTO_GRADING_CTS_REQUESTED, fetchAutoGradingCTS),
    takeLatest(GET_AUTO_GRADING_QUESTIONS_REQUESTED, fetchAutoGradingQuestions),
    takeLatest(UPLOAD_AUTO_GRADING_IMAGE_REQUESTED, uploadAutoGradingImage),
    takeLatest(FETCH_UNATTEMPTED_AUTO_GRADING_REQUESTED, fetchUnattemptedAutoGrading),
    takeLatest(EVALUATE_AUTO_GRADING_IMAGE_REQUESTED, evaluateAutoGradingImage),
    takeLatest(GET_ALL_ASSESSMENT_CLASSES_REQUESTED, getAllAssessmentClasses),
    takeLatest(GET_ASSESSMENT_DRAFT_CLASSES_REQUESTED,getAssessmentDraftClasses),
    takeLatest(ADD_ASSESSMENT_DRAFT_CLASSES_REQUESTED,addAssessmentDraftClasses),
    takeLatest(GET_ASSESSMENT_ADDED_CLASSES_REQUESTED,getAssessmentAddedClasses),
    takeLatest(GET_SCREEN_ERRORS_MAPPING_REQUESTED,getScreenErrorsMapping),
    takeLatest(SUBMIT_LOGIN_ERROR_REPORT_REQUESTED,submitLoginErrorReport),
    takeLatest(SUBMIT_ERROR_REPORT_REQUESTED,submitErrorReport),
    takeLatest(GET_FILTERED_ASSESSMENT_CLASSES_REQUESTED,getFilteredAssessmentClasses),
    takeLatest(RESET_STUDENT_PASSWORD_REQUESTED, resetStudentPassword),
    takeLatest(SET_NEW_STUDENT_PASSWORD_REQUESTED, setNewStudentPassword),
    takeLatest(MODIFY_STUDENT_DETAILS_REQUESTED, modifyStudentDetails),
    takeLatest(GET_CANVAS_CLASS_LIST_REQUEST, fetchCanvasClassList),
    takeLatest(GET_CANVAS_CHAPTER_LIST_REQUEST, fetchCanvasChapterList),
    takeLatest(GET_AI_FEEDBACK_REPORT_REQUESTED, getAIFeedbackReport),
    takeLatest(EDIT_AI_FEEDBACK_CRITERIA_REQUESTED, editAIFeedbackCriteria),
    takeLatest(EDIT_OPEN_ENDED_FEEDBACK_REQUESTED, editOpenEndedFeedback),
    takeLatest(MOCK_TEST_RESET_REQUESTED,resetMockTest),
    takeLatest(DOWNLOAD_TOPIC_REPORT_REQUESTED,downloadTopicReport),
    takeLatest(DOWNLOAD_MOCK_EXAM_REPORT_REQUESTED,downloadMockExamReport),
    takeLatest(GET_AI_GRADING_FEEDBACK_REPORT_REQUESTED,getAIGradingFeedbackReport),
    takeLatest(EDIT_AI_GRADING_FEEDBACK_REQUESTED,editAIGradingFeedback)
  ]);
}

import { useEffect } from 'preact/hooks';
/**
 * @format
 */
export const randomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

export const once = (fn, context) => {
  var result

  return function () {
    if (fn) {
      result = fn.apply(context || this, arguments)
      fn = null
    }

    return result
  }
}

export const userNameSanitizer = /[^a-z0-9@áéíóúñü \.,_-]/gim

export const sanitizeString = (str, regex = userNameSanitizer) => {
  str = str.replace(regex, '')
  return str.trim()
}

export const b64DecodeUnicode = (str) => {
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )
}

export const b64EncodeUnicode = (str) => {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (_, p1) {
      return String.fromCharCode(parseInt(p1, 16))
    }),
  )
}

export const convertToRoman = (num) => {
  let digits = String(+num).split('')
  const key = [
    '',
    'C',
    'CC',
    'CCC',
    'CD',
    'D',
    'DC',
    'DCC',
    'DCCC',
    'CM',
    '',
    'X',
    'XX',
    'XXX',
    'XL',
    'L',
    'LX',
    'LXX',
    'LXXX',
    'XC',
    '',
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX',
  ]
  let romanNum = ''
  let i = 3
  while (i--) romanNum = (key[+digits.pop() + i * 10] || '') + romanNum
  return Array(+digits.join('') + 1).join('M') + romanNum
}

const convertStringToArray = (str) => {
  var i = 0;
  function main() {
    var arr = [];
    var startIndex = i;
    function addWord() {
      if (i-1 > startIndex) {
        arr.push(str.slice(startIndex, i-1));
      }
    }
    while (i < str.length) {
      switch(str[i++]) {
        case ",":
          addWord();
          startIndex = i;
          continue;
        case "[":
          arr.push(main());
          startIndex = i;
          continue;
        case "]":
          addWord();
          return arr;
      }
    }
    addWord();
    return arr;
  }
  return main()[0];
}

export const parseQuestionResponse = userInput => {
    let inputString = userInput && userInput.replace(/\\\\/g, "\\");
    return convertStringToArray(inputString)
}

export const classInfo  = {
    day: ['Mon','Tue','Wed','Thu','Fri'],
    months: ["Jan ", "Feb ", "March ", "April ", "May ", "June ", "July ", "Aug ", "Sept ", "Oct ", "Nov ", "Dec "]
}



export function convertTeacherLanguageToLanguage(obj) {
  obj.language = obj.teacherLanguage;
  delete obj.teacherLanguage;
  return obj;
}

const useDetectOutSideClick = (ref, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler],
  );
};

export default useDetectOutSideClick;



const color="#00C26E"


// const shadow =" 0px 4px 10px 0px rgba(214, 0, 18, 0.15)";


export function convertColorToShadow(color, opacity) {
  // Remove the "#" symbol from the color value
  color = color.replace("#", "");

  // Convert the color value to RGB
  const red = parseInt(color.substr(0, 2), 16);
  const green = parseInt(color.substr(2, 2), 16);
  const blue = parseInt(color.substr(4, 2), 16);

  // Generate the shadow value
  const shadow = `0px 4px 10px 0px rgba(${red}, ${green}, ${blue}, ${opacity})`;

  return shadow;
}

// Example usage
// const color = "#00C26E";
const opacity = 0.15;
const shadow = convertColorToShadow(color, opacity);
// console.log(shadow);

const originList = () => {
  const allowedOrigins = new Set();
  allowedOrigins.add('localhost:3000');
  allowedOrigins.add('superstarteacher.com.sg')
  allowedOrigins.add('localhost');
  allowedOrigins.add('www.superstarteacher.com.sg');
  allowedOrigins.add('demo.superstarteacher.com.sg');
  allowedOrigins.add('uat.superstarteacher.com.sg');
  allowedOrigins.add('mylocal.superstarteacher.com.sg');
  allowedOrigins.add('develop.sparkbackend.' + currentDomain);
  allowedOrigins.add('staging.sparkbackend.' + currentDomain);
  // allowedOrigins.add('bookmyconsultant.in')
  allowedOrigins.add('staging.gms.' + currentDomain);

  // allowedOrigins.add('localhost:8080')
  allowedOrigins.add('administration.manabie.net');
  allowedOrigins.add('learner.manabie.net');
  allowedOrigins.add('backoffice-mfe.prod.tokyo.manabie.io');
  allowedOrigins.add('learner.staging.manabie.io');
  allowedOrigins.add('learner.uat.manabie.io');
  allowedOrigins.add('backoffice-mfe.staging.manabie.io');
  allowedOrigins.add('backoffice-mfe.uat.manabie.io');
  allowedOrigins.add('backoffice.staging.manabie.io');
  allowedOrigins.add('backoffice.uat.manabie.io');
  return allowedOrigins
}

const saveJWT = (jwtKey, jwtValue) => {
  if(location.hostname === "localhost"){
    Cookies.set(jwtKey,jwtValue)
    window[jwtKey]= jwtValue
  }
  else {
    try {
      Cookies.set(jwtKey,jwtValue,{sameSite:'None',expires:30,domain:currentDomain,secure:true})
      window[jwtKey] = jwtValue
    }
    catch(e) {
      console.log(e.message);
    }
  }
}
// const getSavedJWT = (jwtKey) => {
//   return Cookies.get(jwtKey);
// };
// const removeJWT = (jwtKey) => {
//   Cookies.remove(jwtKey);
// }

export const parentMessageListener = (dispatch, initApp) => {
  window.is_integrated = true
  const catchCreds = setInterval(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const auth_tok = urlParams.get("cerebry-jwt-token")
    const class_selected = urlParams.get("class-selected")
    console.log("utils===>", auth_tok, class_selected);
    if(auth_tok && class_selected) {console.log("utils 2===>", auth_tok, class_selected);
      clearInterval(catchCreds);
      window.sparkSSO = true;
      saveJWT('cerebry-jwt-token',auth_tok)
      saveJWT('class-selected',class_selected)
      dispatch(initApp())
    }
  },100)
}

export const insertDataInStaticString = (staticStr, dataArray) => {
  let convertedStr = staticStr
  dataArray.forEach((dataElem, i) => {
      const rgx = new RegExp(`{replace_rgx_${i+1}}`, 'gm')
      convertedStr = convertedStr.replace(rgx, dataElem)
  })
  return convertedStr
}

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

const formatMonthInDate = unformattedDate => {
  let dateArr = unformattedDate.split("/")
  dateArr[0] = monthNames[dateArr[0]-1]
  return dateArr.join(" ")
}

const formatTime = unformattedTime => {
  let timeArr = unformattedTime.split(" ")
  if(timeArr.length == 1) {
      let timeSplit = timeArr[0].split(":")
      let timeModulo = timeSplit[0] >= 12 ? "PM" : "AM"
      if(timeSplit[0]>12) timeSplit[0] -= 12
      return timeSplit[0]+":"+timeSplit[1]+timeModulo
  }
  let hourMin = timeArr[0].split(":")
  hourMin.splice(2,1)
  return hourMin.join(":")+""+timeArr[1].toUpperCase()
}

export const getTimeFormatted = utcFormat => {
  let date = new Date(utcFormat)
  return formatMonthInDate(date.toLocaleDateString())+" "+formatTime(date.toLocaleTimeString())
}

const currentHostname = window.location.hostname.split(".");
const hostSize = currentHostname.length;
const rootDomain = currentHostname[hostSize - 2] || "cerebry";
const TLD = currentHostname[hostSize -1] || "co";
export const currentDomain = hostSize == 1 ? "cerebry.co" : `${rootDomain}.${TLD}`;